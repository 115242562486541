@charset "utf-8";
.m-pageTop{
    display: block;
    position: absolute;
    bottom: 332px;
    right: 38px;
    @include pcMin {
        bottom: 350px;
    }
    @include sp {
        bottom: 840/750*100vw;
        right: 10/750*100vw;
        width: 17/750*100vw;
    }
    // img {
    //     width: 100%;
    // }
}
