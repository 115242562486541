@charset "utf-8";

.p-aboutHistory {
    padding-top: 72px;
    padding-bottom: 132px;
    @include sp {
        padding-top: 87/750*100vw;
        padding-bottom: 262/750*100vw;
    }
    &__kv {
        width: 100%;
        height: 443/1366*100vw;
        padding-top: 143/1366*100vw;
        background: url(../img/about/history_bg.jpg) no-repeat;
        background-size: cover;
        position: relative;
        @include sp {
            height: 558/750*100vw;
            padding-top: 163/750*100vw;
            background: url(../img/about/sp/history_bg_sp.jpg) no-repeat;
            background-size: cover;
        }
    }

    &__chronology {
        width: 927px;
        margin: 0 auto;
        @include sp {
            width: 672/750*100vw;
        }
        .item {
            display: flex;
            padding-bottom: 54px;
            @include sp {
                display: block;
                padding-left: 70/750*100vw;
                padding-bottom: 71/750*100vw;
            }
            .year {
                width: 210px !important;
                margin-right: 54px;
                position: relative;
                font-size: 16px;
                letter-spacing: 0.1em;
                @include sp {
                    width: 300/750*100vw;
                    @include vw(26);
                    margin-bottom: 19/750*100vw;
                }
                &:first-child {
                    &::before {
                        top: 50%;
                    }
                }
                &::before {
                    position: absolute;
                    content: '';
                    width: 1px;
                    height: 120px;
                    background: #098E75;
                    top: 0;
                    @include pc {
                        right: 0;
                    }
                    @include sp {
                        height: 72vw;
                        left: -57/750*100vw;
                    }
                }
                &::after {
                    position: absolute;
                    content: '';
                    width: 9px;
                    height: 9px;
                    background: #098E75;
                    top: 9px;
                    border-radius: 50%;
                    @include pc {
                        right: -4.5px;
                    }
                    @include sp {
                        width: 16/750*100vw;
                        height: 16/750*100vw;
                        left: -64/750*100vw;
                        top: 2vw;
                    }
                }
            }
            .pre {
                &::before {
                    height: 98px;
                    @include sp {
                        height: 6.8vw;
                    }
                }
            }
            .last {
                &::before {
                    display: none;
                }
            }
            .txt {
                width: 665px;
                display: flex;
                @include sp {
                    width: 592/750*100vw;
                    display: block;
                }
                span {
                    display: block;
                    font-size: 14px;
                    letter-spacing: 0.1em;
                    width: 80px;
                    @include sp {
                        @include vw(26);
                        margin-bottom: 14/750*100vw;
                    }
                }
                p {
                    width: 560px;
                    font-size: 16px;
                    letter-spacing: 0.1em;
                    @include sp {
                        width: 100%;
                        @include vw(26);
                    }
                }
            }
        }
    }
}