.p-terms {
    padding: 96px 0 130px;
    @include sp {
        padding-top: 87/750*100vw;
        padding-bottom: 309/750*100vw;
    }
    &__top {
        padding-bottom: 80px;
        border-bottom: 1px solid #F6FAF3;
        margin-bottom: 69px;
        @include sp {
            padding-bottom: 128/750*100vw;
            border-bottom: 4/750*100vw solid #F6FAF3;
            margin-bottom: 67/750*100vw;
        }
    }
    &__ttl {
        width: 1120px;
        margin: 0 auto;
        @include sp {
            width: 670/750*100vw;
        }
        .ttl {
            display: block;
            font-size: 42px;
            letter-spacing: 0.1em;
            line-height: 1.2;
            color: #000;
            font-weight: 500;
            margin-bottom: 5px;
            @include sp {
                @include vw(50);
                margin-bottom: 6/750*100vw;
            }
        }
        .sub_ttl {
            font-family: 'Lato', sans-serif !important;
            font-size: 20px;
            letter-spacing: 0.04em;
            color: #098E75;
            @include sp {
                @include vw(28);
            }
        }
    }
    &__cont {
        width: 930px;
        margin: 0 auto;
        @include sp {
            width: 672/750*100vw;
        }
    }
    &__block {
        margin-bottom: 89px;
        @include sp {
            margin-bottom: 140/750*100vw;
        }
        &:last-child {
            margin-bottom: 0;
        }
        h3 {
            font-size: 22px;
            letter-spacing: 0.08em;
            padding-bottom: 7px;
            margin-bottom: 30px;
            line-height: 2.2;
            position: relative;
            @include sp {
                @include vw(32);
                padding-bottom: 7/750*100vw;
                margin-bottom: 40/750*100vw;
                line-height: 1.5;
            }
            &::before {
                position: absolute;
                content: '';
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                background: #E8EAEA;
                @include sp {
                    height: 4/750*100vw;
                }
            }
            &::after {
                position: absolute;
                content: '';
                left: 0;
                bottom: 0;
                width: 36px;
                height: 2px;
                background: #098E75;
                @include sp {
                    width: 51/750*100vw;
                    height: 4/750*100vw;
                }
            }
        }
        p {
            font-size: 16px;
            letter-spacing: 0.1em;
            @include pc {
                line-height: 2;
            }
            @include sp {
                @include vw(26);
                line-height: 1.9;
            }
        }
    }

    .blank {
        position: relative;
        &::after {
            position: absolute;
            content: '';
            width: 14px;
            height: 11px;
            background: url(../img/common/blank.svg) no-repeat;
            background-size: cover;
            right: -20px;
            top: 50%;
            transform: translateY(-50%);
            @include sp {
                width: 30/750*100vw;
                height: 24/750*100vw;
                right: -40/750*100vw;
            }
        }
    }

    .reader {
        display: block;
        margin-top: 5px;
        @include sp {
            width: 250/750*100vw;
            margin-top: 1vw;
        }
    }
    .link {
        text-decoration:underline;
    }
}