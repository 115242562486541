@charset "utf-8";

.p-sdgs {
    padding-top: 72px;
    @include sp {
        padding-top: 87/750*100vw;
    }
    &__kv {
        width: 100%;
        height: 443/1366*100vw;
        padding-top: 143/1366*100vw;
        background: url(../img/sdgs/sdgs_bg.jpg) no-repeat;
        background-size: cover;
        position: relative;
        @include sp {
            height: 558/750*100vw;
            padding-top: 163/750*100vw;
            background: url(../img/sdgs/sp/sdgs_bg_sp.jpg) no-repeat;
            background-size: cover;
        }
    }
    &__intro {
        text-align: center;
        font-size: 16px;
        letter-spacing: 0.1em;
        line-height: 1.37;
        margin-bottom: 63px;
        @include sp {
            width: 672/750*100vw;
            letter-spacing: 0.05em;
            @include vw(26);
            line-height: 1.9;
            margin: 0 auto 76/750*100vw;
        }
    }
    &__introImg {
        text-align: center;
        margin-bottom: 122px;
        @include sp {
            width: 336/750*100vw;
            margin: 0 auto 202/750*100vw;
        }
    }
    
    &__topCont {
        margin: 0 auto;
        padding-bottom: 289px;
        position: relative;
        @include sp {
            // width: 672/750*100vw;
            padding-bottom: 333/750*100vw;
        }
        .introMask {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100vw;
            img {
                width: 100%;
            }
        }
    }

    &__contTtl {
        width: 1025px;
        padding-bottom: 9px;
        margin: 0 auto 30px;
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0.08em;
        position: relative;
        @include sp {
            width: 672/750*100vw;
            padding-bottom: 7/750*100vw;
            margin-bottom: 48/750*100vw;
            @include vw(32);
            line-height: 1.5;
        }
        &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 2px;
            background: #E8EAEA;
            bottom: 0;
            left: 0;
            @include sp {
                height: 4/750*100vw;
            }
        }
        &::after {
            position: absolute;
            content: '';
            width: 36px;
            height: 2px;
            background: #098E75;
            bottom: 0;
            left: 0;
            @include sp {
                width: 51/750*100vw;
                height: 4/750*100vw;
            }
        }
    }
    &__topContTxt {
        width: 1025px;
        margin: 0 auto;
        font-size: 16px;
        letter-spacing: 0.1em;
        line-height: 1.37;
        @include sp {
            width: 672/750*100vw;
            @include vw(26);
            line-height: 1.9;
        }
    }

    &__cont {
        background: #F8FAFA;
        padding-bottom: 156px;
        @include sp {
            padding: 5vw 0 238/750*100vw;
        }
    }
    &__list {
        width: 1025px;
        margin: 0 auto;
        @include sp {
            width: 672/750*100vw;
        }
    }
    &__listItem {
        width: 100%;
        padding: 42px 0 49px;
        background: #fff;
        margin-bottom: 30px;
        border-radius: 15px;
        @include sp {
            padding: 67/750*100vw 0 107/750*100vw;
            margin-bottom: 60/750*100vw;
            border-radius: 25/750*100vw;
        }
        .inner {
            width: 850px;
            margin: 0 auto;
            @include sp {
                width: 528/750*100vw;
            }
            h4 {
                font-size: 22px;
                letter-spacing: 0.8;
                font-weight: 500;
                margin-bottom: 22px;
                @include sp {
                    @include vw(26);
                    margin-bottom: 48/750*100vw;
                    text-align: center;
                }
            }

            .box {
                display: flex;
                justify-content: space-between;
                @include sp {
                    display: block;
                }
                .img {
                    width: 155px;
                    @include sp {
                        width: 248/750*100vw;
                        margin: 0 auto 40/750*100vw;
                    }
                }
                .txt {
                    width: 678px;
                    @include sp {
                        width: 100%;
                    }
                    h5 {
                        font-size: 16px;
                        letter-spacing: 0.1em;
                        line-height: 1;
                        padding-bottom: 15px;
                        margin-bottom: 17px;
                        position: relative;
                        @include sp {
                            @include vw(26);
                            padding-bottom: 15/750*100vw 0 24/750*100vw;
                        }
                        &::after {
                            position: absolute;
                            content: '';
                            width: 100%;
                            height: 1px;
                            bottom: 0;
                            left: 0;
                            @include sp {
                                height: 2/750*100vw;
                            }
                        }
                    }
                    ul {
                        li {
                            font-size: 16px;
                            letter-spacing: 0.1em;
                            line-height: 1.3;
                            @include sp {
                                @include vw(26);
                            }
                        }
                    }
                }
            }
        }
    }
}

.item01 {
    color: #0098D1;
}
.item02 {
    color: #830025;
}
.item03 {
    color: #CD7D00;
}
.ttl01 {
    &::after {
        background: #0098D1;
    }
}
.ttl02 {
    &::after {
        background: #830025;
    }
}
.ttl03 {
    &::after {
        background: #CD7D00;
    }
}