@charset "utf-8";

.p-message {
    padding-top: 72px;
    padding-bottom: 189px;
    &__kv {
        width: 100%;
        height: 443/1366*100vw;
        padding-top: 147/1366*100vw;
        background: url(../img/message/message_bg.jpg) no-repeat;
        background-size: cover;
        position: relative;
        @include sp {
            height: 558/750*100vw;
            padding-top: 163/750*100vw;
            background: url(../img/message/sp/message_bg_sp.jpg) no-repeat;
            background-size: cover;
        }
    }
    &__inner {
        width: 930px;
        margin: 0 auto;
        @include sp {
            width: 672/750*100vw;
        }
    }
    &__intro {
        font-size: 16px;
        letter-spacing: 0.1em;
        line-height: 2.25;
        margin-bottom: 94px;
        @include sp {
            @include vw(26);
            line-height: 1.9;
            margin-bottom: 176/750*100vw;
        }
    }

    &__name {
        text-align: right;
        font-size: 18px;
        letter-spacing: 0.1em;
        margin-bottom: 118px;
        @include sp {
            @include vw(28);
            margin-bottom: 190/750*100vw;
        }
    }

    .block_ttl {
        margin-bottom: 45px;
        @include sp {
            margin-bottom: 70/750*100vw;
        }
    }

    &__mp {
        text-align: center;
        font-size: 26px;
        font-weight: 500;
        letter-spacing: 0.1em;
        line-height: 1.75;
        @include sp {
            @include vw(36);
            line-height: 1.75;
            letter-spacing: 0.1em;
        }
    }
}