@charset "utf-8";

.p-products {
    padding-bottom: 160px;
    @include pc {
        padding-top: 72px;
    }
    @include sp {
        padding: 87/750*100vw 0 249/750*100vw;
    }
    &__kv {
        width: 100%;
        height: 443/1366*100vw;
        background: url(../img/products/products_bg.jpg) no-repeat;
        background-size: cover;
        position: relative;
        padding-top: 143/1366*100vw;
        @include sp {
            background: url(../img/products/sp/products_bg_sp.jpg) no-repeat;
            background-size: cover;
            height: 558/750*100vw;
            padding-top: 163/750*100vw;
        }
    }

    &__intro {
        padding-bottom: 268px;
        position: relative;
        @include sp {
            padding-bottom: 354/750*100vw;
        }
        .introMask {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100vw;
            img {
                width: 100%;
            }
        }
    }
    &__introInner {
        width: 1120px;
        margin: 0 auto;
        @include sp {
            width: 672/750*100vw;
        }
    }
    &__introTxtTop {
        font-size: 16px;
        letter-spacing: 0.1em;
        text-align: center;
        margin-bottom: 88px;
        @include sp {
            @include vw(26);
            margin-bottom: 121/750*100vw;
        }
    }
    &__introTtl {
        text-align: center;
        margin-bottom: 40px;
        @include sp {
            margin-bottom: 70/750*100vw;
        }
        .ttl {
            font-size: 32px;
            font-weight: 500;
            letter-spacing: 0.1em;
            display: block;
            margin-bottom: 6px;
            line-height: 1;
            @include sp {
                @include vw(46);
                margin-bottom: 2/750*100vw;
            }
        }
        .sub_ttl {
            font-family: 'Lato', sans-serif !important;
            font-size: 14px;
            letter-spacing: 0.04em;
            color: #098E75;
            @include sp {
                @include vw(28);
            }
        }
    }
    &__introTxt {
        text-align: center;
        margin-bottom: 98px;
        font-size: 16px;
        letter-spacing: 0.1em;
        line-height: 2.1;
        @include sp {
            @include vw(26);
            margin-bottom: 116/750*100vw;
        }
    }

    &__introItem {
        position: relative;
        width: 100%;
        height: 535px;
        @include sp {
            width: 100%;
            position: static;
            height: auto;
        }
        &--01, &--03 {
            margin-bottom: 85px;
            @include sp {
                margin-bottom: 125/750*100vw;
            }
            .img {
                @include pc {
                    text-align: right;
                }
                @include sp {
                    margin-bottom: 51/750*100vw;
                }
            }
            .item {
                @include sp {
                    position: static !important;
                }
            }
        }
        &--01 {
          .item {
            @include pc {
                position: absolute;
                right: 180px;
                top: 31px;
            }
          }
        }
        &--02 {
            margin-bottom: 85px;
            img {
                @include sp {
                    margin-bottom: 51/750*100vw;
                }
            }
            .item {
                @include pc {
                    position: absolute;
                    left: 210px;
                    top: 31px;
                }
            }
            .textWrap {
                right: 0;
                padding-left: 80px;
                @include sp {
                    padding-left: 0;
                }
            }
        }
        &--03 {
          .item {
                position: absolute;
                right: 125px;
                top: 31px;
          }
        }
        .textWrap {
            width: 550px;
            padding-top: 65px;
            background: #fff;
            border-radius: 20px;
            @include pc {
                position: absolute;
                top: 80px;
            }
            @include sp {
                width: 100%;
                padding-top: 0;
                border-radius: none;
            }
            h4 {
                font-size: 32px;
                font-weight: 500;
                letter-spacing: 0.1em;
                line-height: 1.25;
                margin-bottom: 30px;
                @include sp {
                    @include vw(46);
                    text-align: center;
                    margin-bottom: 57/750*100vw;
                }
            }
            p {
                width: 470px;
                margin-bottom: 48px;
                font-size: 16px;
                letter-spacing: 0.1em;
                line-height: 2.25;
                @include sp {
                    width: 100%;
                    @include vw(26);
                    margin-bottom: 76/750*100vw;
                }
            }
            .btn {
                margin-left: 216px;
                @include sp {
                    margin: 0 auto;
                }
            }
        }
    }

    &__contract {
        
    }
    &__contractItemWrap {
        display: flex;
        justify-content: space-between;
        @include sp {
            flex-wrap: wrap;
        }
    }
    &__contractItem {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.1em;
        @include sp {
            width: 327/750*100vw;
            @include vw(28);
        }
        &:first-child {
            @include sp {
                margin-bottom: 97/750*100vw;
            }
        }
        img {
            margin-bottom: 25px;
            display: block;
            @include sp {
                margin-bottom: 32/750*100vw;
            }
        }
    }

    &__group {
        width: 100%;
        background-color: #F8FAFA;
        padding-bottom: 285px;
        position: relative;
        @include sp {
            padding-bottom: 281/750*100vw;
        }
        .groupMask {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100vw;
            img {
                width: 100%;
            }
        }
    }
    &__groupInner {
        width: 1120px;
        margin: 0 auto;
        padding-top: 30px;
        @include sp {
            padding-top: 12vw;
            width: 670/750*100vw;
        }
    }
    &__groupItemWrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 80px;
        @include sp {
            margin-bottom: 116/750*100vw;
        }
        .item {
            width: 265px;
            text-align: center;
            @include sp {
                width: 327/750*100vw;
            }
            &:nth-child(-n + 4) {
                @include pc {
                    margin-bottom: 62px;
                }
            }
            &:nth-child(-n + 6) {
                @include sp {
                    margin-bottom: 64/750*100vw;
                }
            }
            img {
                margin-bottom: 25px;
                @include sp {
                    margin-bottom: 32/750*100vw;
                }
            }
            span {
                font-size: 18px;
                letter-spacing: 0.1em;
                line-height: 1.55;
                @include sp {
                    @include vw(28);
                }
            }
        }
    }
    
    &__groupBtn {
        width: 251px;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        background: #fff;
        border-radius: 30px;
        border: 1px solid #098E75;
        margin: 0 auto;
        position: relative;
        padding-left: 35px;
        @include sp {
            width: 500/750*100vw;
                height: 100/750*100vw;
                padding-left: 50/414*100vw;
                // padding-top: 1vw;
                text-align: center;
        }
        img {
            width: 157px;
            // margin: 0 auto;
            @include sp {
                width: 261/750*100vw;
                // margin: 0 auto;
                // margin-top: 1.5vw;
            }
        }
        &::after {
            position: absolute;
            content: '';
            width: 14px;
            height: 11px;
            background: url(../img/common/blank.svg) no-repeat;
            background-size: cover;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            @include sp {
                width: 30/750*100vw;
                height: 24/750*100vw;
                right: 31/750*100vw;
            }
        }
    }

    &__recommend {}
    &__recommendInner {
        width: 1120px;
        margin: 0 auto;
        @include sp {
            width: 672/750*100vw;
            padding-top: 5vw;
        }
    }
    &__recomendCont {
        display: flex;
        justify-content: space-between;
        @include sp {
            display: block;
        }
    }
    &__recomendItem {
        display: block;
        width: 530px;
        height: 428px;
        position: relative;
        @include sp {
            width: 100%;
            height:649/750*100vw;
        }
        &:last-child {
            @include sp {
                height:695/750*100vw;
            }
        }
        &:first-child {
            @include sp {
                margin-bottom: 80/750*100vw;
            }
        }
        &::before {
            position: absolute;
            content: '';
            width: 28px;
            height: 28px;
            border: 1px solid #098e75;
            border-radius: 50%;
            right: 85px;
            bottom: -26px;
            z-index: 20;
            @include sp {
                width: 46/750*100vw;
                height: 46/750*100vw;
                bottom: 95/750*100vw;
                right: 56/750*100vw;
            }
        }
        &::after {
            position: absolute;
            content: '';
            width: 20px;
            height: 4px;
            background: url(../img/common/arrow.svg) no-repeat;
            background-size: cover;
            right: 89px;
            bottom: -12px;
            transition: .4s;
            @include sp {
                z-index: 30;
                width: 28/750*100vw;
                height: 5/750*100vw;
                bottom: 118/750*100vw;
                right: 67/750*100vw;
            }
        }
        &:hover::after {
            right: 79px;
            @include sp {
                right: 57/750*100vw;
            }
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
        }
        .txtWrap {
            position: absolute;
            width: 490px;
            height: 180px;
            top: 252px;
            left: 0;
            background: #fff;
            border-radius: 0 20px 0 0;
            padding: 40px 40px 24px;
            z-index: 10;
            @include sp {
                padding: 40/750*100vw 56/750*100vw 0;
                width: 612/750*100vw;
                height: 259/750*100vw;
                top: 278/750*100vw;
                border-radius: 0 25/750*100vw 0 0;
            }
            .ttl {
                display: block;
                font-size: 24px;
                font-weight: 500;
                letter-spacing: 0.1em;
                line-height: 1.37;
                margin-bottom: 17px;
                @include sp {
                    @include vw(36);
                    margin-bottom: 19/750*100vw;
                }
            }
            .txt {
                display: block;
                font-size: 16px;
                letter-spacing: 0.1em;
                line-height: 1.37;
                @include sp {
                    @include vw(26)
                }
            }
        }
    }
}
