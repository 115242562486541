@charset "utf-8";

.p-development {
    padding-top: 72px;
    padding-bottom: 132px;
    @include sp {
        padding-top: 87/750*100vw;
        padding-bottom: 266/750*100vw;
    }
    &__kv {
        width: 100%;
        height: 443/1366*100vw;
        padding-top: 147/1366*100vw;
        background: url(../img/development/development_bg.jpg) no-repeat;
        background-size: cover;
        position: relative;
        @include sp {
            @include sp {
                height: 558/750*100vw;
                padding-top: 163/750*100vw;
                background: url(../img/development/sp/development_bg_sp.jpg) no-repeat;
                background-size: cover;
            }
        }
    }
    &__contTtl {
        width: 414px;
        margin: 0 auto 24px;
        padding-bottom: 7.5px;
        font-size: 22px;
        font-weight: bold;
        color: #098E75;
        line-height: 2;
        text-align: center;
        position: relative;
        @include sp {
            width: 405/750*100vw;
            @include vw(36);
            line-height: 1.3;
            padding-bottom: 14/750*100vw;
            margin-bottom: 32/750*100vw;
        }
        &::after {
            position: absolute;
            content: '';
            width: 100%;
            height: 12.7px;
            background: url(../img/development/ttl_decoration.svg) no-repeat;
            background-size: cover;
            bottom: 0;
            left: 0;
            @include sp {
                bottom: -14/750*100vw;
                height: 20/750*100vw;
                background: url(../img/development/ttl_decoration.svg) no-repeat;
                background-size: 100% 12.7/750*100vw;
            }
        }
    }
    &__contTxt {
        font-size: 16px;
        letter-spacing: 0.1em;
        text-align: center;
        margin-bottom: 77px;
        @include sp {
            @include vw(26);
            line-height: 1.9;
            margin-bottom: 91/750*100vw;
        }
    }
    &__anchor {
        width: 604px;
        margin: 0 auto 113px;
        display: flex;
        justify-content: space-between;
        @include sp {
            display: block;
            width: 500/750*100vw;
            margin: 0 auto 150/750*100vw;
        }
        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            width: 282px;
            height: 60px;
            border-radius: 30px;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.1em;
            color: #098E76;
            border: 1px solid #098E76;
            position: relative;
            @include sp {
                width: 100%;
                height: 100/750*100vw;
                @include vw(28);
                margin-bottom: 40/750*100vw;
            }
            &::after {
                position: absolute;
                content: '';
                width: 4px;
                height: 22px;
                background: url(../img/common/anchor.svg) no-repeat;
                background-size: cover;
                right: 24px;
                top: 50%;
                transform: translateY(-50%);
                transition:0.4s;
                @include sp {
                    width: 8/750*100vw;
                    height: 44/750*100vw;
                    right: 50/750*100vw;
                }
            }
            &:hover::after {
                top: 65%;
            }
        }
    }

    &__service {
        position: relative;
        .introMask {
            position: absolute;
            bottom: 0;
            width: 100%;
            // @include sp {
            //     display: none;
            // }
            img {
                width: 100%;
            }
        }
    }
    &__serviceInner {
        width: 980px;
        margin: 0 auto;
        @include sp {
            width: 672/750*100vw;
        }
    }
    &__serviceItemWrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 72px;
        @include sp {
            display: block;
            margin-bottom: 91/750*100vw;
        }
    }
    &__serviceItem {
        width: 445px;
        height: 332px;
        margin-bottom: 61px;
        position: relative;
        @include sp {
            width:672/750*100vw;
            height: 520/750*100vw;
            margin-bottom: 121/750*100vw;
        }
        &:nth-child(n + 5) {
            @include pc {
                margin-bottom: 0;
            }
        }
        &:last-child {
            @include sp {
                margin-bottom: 0;
            }
        }
        .img {
            @include sp {
                width: 100%;
            }
        }
        .txt {
            width: 405px;
            padding-top: 24px;
            background: #fff;
            border-radius: 0 20px 0 0;
            position: absolute;
            &--wide {
                padding-bottom: 48px;
                @include sp {
                    padding-bottom: 58/750*100vw;
                }
            }
            @include pc {
                top: 192px;
            }
            // bottom: 10px;
            left: 0;
            z-index: 5;
            @include sp {
                width: 612/750*100vw;
                padding-top: 40/750*100vw;
                border-radius: 0 30/750*100vw 0 0;
                bottom: 0;
                left: -5/750*100vw;
            }
            .inner {
                width: 325px;
                margin: 0 auto;
                @include sp {
                    width: 490/750*100vw;
                    margin: 0 auto;
                }
                .ttl {
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 1.3;
                    letter-spacing: 0.1em;
                    margin-bottom: 13px;
                    @include sp {
                        @include vw(36);
                        margin-bottom: 19/750*100vw;
                    }
                }
                .desc {
                    font-size: 16px;
                    line-height: 1.35;
                    letter-spacing: 0.1em;
                    @include sp {
                        @include vw(26);
                        line-height: 1.6;
                    }
                }
            }
        }
    }

    &__achievements {
        width: 100%;
        padding: 30px 0;
        background: #F8FAFA;
        border-radius: 20px;
        margin-bottom: 113px;
        @include sp {
            padding: 68/750*100vw 0 80/750*100vw;
            border-radius: 30/750*100vw;
            margin-bottom: 124/750*100vw;
        }
        .inner {
            width: 880px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            @include sp {
                display: block;
                width: 620/750*100vw;
            }
            .txt {
                padding-top: 12px;
                @include sp {
                    padding-top: 0;
                    text-align: center;
                    margin-bottom: 60/750*100vw;
                }
                .ttl {
                    font-size: 22px;
                    color: #098E75;
                    letter-spacing: 0.08em;
                    margin-bottom: 17px;
                    @include sp {
                        @include vw(36);
                        letter-spacing: 0.1em;
                        margin-bottom: 35/750*100vw;
                    }
                }
                .desc {
                    font-size: 16px;
                    letter-spacing: 0.1em;
                    line-height: 1.37;
                }
            }
            .img {
                @include sp {
                    width: 552/750*100vw;
                    margin: 0 auto;
                }
            }
        }
    }

    &__process {
        padding-bottom: 245px;
        position: relative;
        @include sp {
            padding-bottom: 420/750*100vw;
        }
    }
    &__processInner {
        width: 930px;
        margin: 0 auto;
        @include sp {
            width: 672/750*100vw;
        }
        .block_ttl {
            margin-bottom: 62px;
            @include sp {
                margin-bottom: 70/750*100vw;
            }
        }
    }
    &__processFigure {
        width: 100%;
        display: flex;
        justify-content: space-between;
        vertical-align: middle;
        margin-bottom: 62px;
        @include sp {
            margin-bottom: 83/750*100vw;
            display: block;
            padding: 0 60/750*100vw;
        }
        .img01 {
            @include sp {
                width: 552/750*100vw;
                margin-bottom: 10/750*100vw;
            }
        }
        .img02 {
            @include sp {
                width: 552/750*100vw;
                // margin-left: 6vw;
            }
        }
    }
    &__processFigureTxt {
        width: 384px;
        @include sp {
            width: 551/750*100vw;
            margin-bottom: 21/750*100vw;
        }
        .wrap {
            .ttl {
                font-size: 18px;
                color: #098E75;
                line-height: 1.7;
                margin-bottom: 9px;
                letter-spacing: 0.08em;
                display: block;
                @include sp {
                    @include vw(26);
                    margin-bottom: 9/750*100vw;
                }
            }
            .txt {
                font-size: 13px;
                letter-spacing: 0.1em;
                line-height: 1.46;
                @include sp {
                    @include vw(24);
                }
            }
            &--01 {
                padding-bottom: 35px;
                margin-bottom: 22px;
                border-bottom: 1px solid #CCCCCC;
                @include sp {
                    padding-bottom: 24/750*100vw;
                    margin-bottom: 24/750*100vw;
                }
            }
            &--02 {
                padding-bottom: 20px;
                margin-bottom: 20px;
                border-bottom: 1px solid #CCCCCC;
                @include sp {
                    padding-bottom: 24/750*100vw;
                    margin-bottom: 24/750*100vw;
                }
            }
            &--03 {
                padding-bottom: 20px;
                margin-bottom: 20px;
                border-bottom: 1px solid #CCCCCC;
                @include sp {
                    padding-bottom: 24/750*100vw;
                    margin-bottom: 24/750*100vw;
                }
            }
        }
    }

    &__figureCont {
        margin-bottom: 82px;
        @include sp {
            margin-bottom: 102/750*100vw;
        }
        &:last-child {
            margin-bottom: 0;
        }
        h5 {
            width: 100%;
            font-size: 22px;
            font-weight: 500;
            letter-spacing: 0.08em;
            line-height: 2.2;
            padding-bottom: 9px;
            margin-bottom: 30px;
            position: relative;
            @include sp {
                @include vw(32);
                line-height: 1.5;
                padding-bottom: 7/750*100vw;
                margin-bottom: 40/750*100vw;
            }
            &::before {
                position: absolute;
                content: '';
                width: 100%;
                height: 2px;
                background: #E8EAEA;
                bottom: 0;
                left: 0;
                @include sp {
                    height: 4/750*100vw;
                }
            }
            &::after {
                position: absolute;
                content: '';
                width: 36px;
                height: 2px;
                background: #098E75;
                bottom: 0;
                left: 0;
                @include sp {
                    width: 41/750*100vw;
                    height: 4/750*100vw;
                }
            }
        }
        p{
            font-size: 16px;
            letter-spacing: 0.1em;
            line-height: 1.375;
            @include sp {
                @include vw(26);
                line-height: 1.9;
            }
        }
        .box {
            display: flex;
            justify-content: space-between;
            width: 904px;
            margin: 0 auto;
            margin-top: 50px;
            @include sp {
                display: block;
                width: 100%;
                text-align: center;
            }
            .cont {
                width: 283px;
                text-align: center;
                @include sp {
                    width: 100%;
                    margin-bottom: 80/750*100vw;
                }
                &:last-child {
                    @include sp {
                        margin-bottom: 0;
                    }
                }
                img {
                    width: 100%;
                    margin-bottom: 20px;
                    @include sp {
                        width: 504/750*100vw;
                        margin-bottom: 32/750*100vw;
                    }
                }
                .ttl {
                    display: block;
                    margin-bottom: 20px;
                    font-size: 18px;
                    font-weight: 600;
                    @include sp {
                        margin-bottom: 10/750*100vw;
                        @include vw(28);
                    }
                }
                .txt {
                    display: block;
                    font-size: 16px;
                    letter-spacing: 0.05em;
                    line-height: 26/16*1;
                    @include sp {
                        @include vw(26);
                        letter-spacing: 0.1em;
                        line-height: 50/26*1;
                    }
                }
            }
            // .img01 {
            //     @include sp {
            //         width: 407/750*100vw;
            //         margin-bottom: 85/750*100vw;
            //     }
            // }
            // .img02 {
            //     @include sp {
            //         width: 408/750*100vw;
            //         margin-bottom: 85/750*100vw;
            //     }
            // }
            // .img03 {
            //     @include pc {
            //         margin-top: -24px;
            //     }
            //     @include sp {
            //         width: 638/750*100vw;
            //     }
            // }
        }
    }

    &__path {
        background: #F8FAFA;
        padding-bottom: 334px;
        position: relative;
        @include sp {
            padding-bottom:395/750*100vw;
        }
        .groupMask {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100vw;
            img {
                width: 100%;
            }
        }
    }
    &__pathInner {
        width: 930px;
        margin: 0 auto;
        @include sp {
            width: 672/750*100vw;
        }
    }
    &__pathTtl {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        @include sp {
            display: block;
            margin-bottom: 69/750*100vw;
            position: relative;
        }
        .ttl {
            font-weight: bold;
            letter-spacing: 0.1em;
            color: #098E75;
            font-size: 43px;
            margin-top: auto;
            @include sp {
                letter-spacing: 0.07em;
                @include vw(67);
                margin-top: 0;
            }
            .top {
                font-size: 22px;
                display: block;
                color: #000;
                @include sp {
                    @include vw(30);
                }
            }
            .small {
                font-size: 22px;
                @include sp {
                    @include vw(30);
                    display: inline-block;
                    margin-left: -1em;
                }
            }
        }
        .img {
            padding-right: 62px;
            @include sp {
                padding-right: 0;
                position: absolute;
                right: 0;
                bottom: -40/750*100vw;
                width: 204/750*100vw;
            }
        }
    }
    &__pathCont {
        width: 100%;
        padding: 40px 45px;
        background: #fff;
        border-radius: 20px;
        @include sp {
            padding: 60/750*100vw 0 50/750*100vw;
            border-radius: 20/750*100vw;
        }
        .item {
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            @include sp {
                display: block;
                margin-bottom: 71/750*100vw;
            }
            .img {
                margin-right: 24px;
                width: 138px;
                margin-right: 24px;
                position: relative;
                z-index: 9;
                img {
                    @include pc {
                        width: 138px;
                    }
                }
                @include sp {
                    width: 328/750*100vw;
                    margin: 0 auto 26/750*100vw;
                }
                &:after {
                    position: absolute;
                    content: '';
                    width: 2px;
                    height: 45px;
                    background: #E8EAEA;
                    bottom: -44px;
                    right: 65px;
                    z-index: 10;
                    @include sp {
                        display: none;
                    }
                }
                &--last {
                    &::after {
                        display: none;
                    }
                }
            }
            .txt {
                .ttl {
                    font-size: 22px;
                    font-weight: bold;
                    letter-spacing: 0.08em;
                    margin-bottom: 11px;
                    @include sp {
                        @include vw(32);
                        text-align: center;
                        margin-bottom: 18/750*100vw;
                    }
                }
                .desc {
                    font-size: 16px;
                    letter-spacing: 0.1em;
                    line-height: 1.3;
                    @include sp {
                        @include vw(26);
                        line-height: 1.9;
                        margin: 0 auto;
                        text-align: center;
                    }
                }
                .sub_ttl {
                    font-size: 16px;
                    letter-spacing: 0.1em;
                    padding-left: 18px;
                    margin: 8px 0 2px;
                    position: relative;
                    @include sp {
                        @include vw(26);
                        padding-left:32/750*100vw;
                        margin: 36/750*100vw auto 8/750*100vw;
                        width: 482/750*100vw;
                        text-align: center;
                    }
                    &:before {
                        position: absolute;
                        content: '';
                        width: 16px;
                        height: 2px;
                        background: #098E75;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        @include sp {
                            width: 24/750*100vw;
                            height: 2/750*100vw;
                        }
                    }
                }
                .anot {
                    font-size: 14px;
                    letter-spacing: 0.1em;
                    @include sp {
                        @include vw(22);
                        width: 482/750*100vw;
                        text-align: left;
                        margin: 0 auto;
                    }
                }
            }
        }
    }

    &__inquiry {
        @include sp {
            padding-top: 105/750*100vw;
        }
    }
    &__inquiryTxt {
        text-align: center;
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 57px;
        @include sp {
            @include vw(32);
            width: 674/750*100vw;
            margin: 0 auto 69/750*100vw;
        }
    }
}

.block_ttl {
    margin-bottom: 40px;
    text-align: center;
    @include sp {
        margin-bottom: 70/750*100vw;
    }
    .ttl {
        display: block;
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 6px;
        line-height: 1.2;
        @include sp {
            @include vw(46);
            margin-bottom: 2/750*100vw;
            line-height: 1.12;
        }
    }
    .sub_ttl {
        font-family: 'Lato', sans-serif !important;
        font-size: 14px;
        letter-spacing: 0.04em;
        color: #098E75;
        @include sp {
            @include vw(28);
        }
    }
}

#service {
    padding-top: 72px;
    margin-top:-72px;
    @include sp {
        padding-top: 50/414*100vw;
        margin-top: -50/414*100vw;
    }
}
#process {
    padding-top: 72px;
    margin-top:-72px;
    @include sp {
        padding-top: 50/414*100vw;
        margin-top: -50/414*100vw;
    }
}