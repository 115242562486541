@charset "utf-8";

.p-aboutCompany {
    @include pc {
        padding-top: 72px;
    }
    @include sp {
        padding-top: 87/750*100vw;
    }
    &__kv {
        width: 100%;
        height: 443/1366*100vw;
        padding-top: 147/1366*100vw;
        background: url(../img/about/company_bg.jpg) no-repeat;
        background-size: cover;
        position: relative;
        @include sp {
            background: url(../img/about/sp/company_bg_sp.jpg) no-repeat;
            background-size: cover;
            height: 558/750*100vw;
            padding-top: 163/750*100vw;
        }
    }

    &__ttl {
        text-align: center;
        margin-bottom: 57px;
        @include sp {
            margin-bottom: 67/750*100vw;
        }
        .ttl {
            display: block;
            font-size: 32px;
            font-weight: 500;
            margin-bottom: 6px;
            line-height: 1.6;
            letter-spacing: 0.1em;
            @include sp {
                @include vw(46);
                margin-bottom: 2/750*100vw;
            }
        }
        .sub_ttl {
            font-family: 'Lato', sans-serif !important;
            font-size: 14px;
            letter-spacing: 0.04em;
            color: #098E75;
            @include sp {
                @include vw(28);
            }
        }
    }
    &__overView {
        width: 930px;
        margin: 0 auto 149px;
        @include sp {
            width: 672/750*100vw;
            margin-bottom: 166/750*100vw;
        }
        .item {
            display: flex;
            padding-left: 39px;
            border-bottom: 1px solid #E8EAEA;
            padding-top: 22px;
            padding-bottom: 22px;
            @include sp {
                padding: 40/750*100vw 0;
            }
            // &--01 {
            //     @include pc {
            //         padding-bottom: 20px;
            //     }
            // }
            // &--02 {
            //     @include pc {
            //         padding-top: 22px;
            //         padding-bottom: 22px;
            //     }
            // }
            // &--03 {
            //     @include pc {
            //         padding-top: 21px;
            //         padding-bottom: 38px;
            //     }
            // }
            // &--04 {
            //     @include pc {
            //         padding-top: 21px;
            //         padding-bottom: 38px;
            //     }
            // }
            // &--05 {
            //     @include pc {
            //         padding-top: 21px;
            //         padding-bottom: 38px;
            //     }
            // }
            // &--06 {
            //     @include pc {
            //         padding-top: 21px;
            //         padding-bottom: 30px;
            //     }
            // }
            // &--07 {
            //     @include pc {
            //         padding-top: 21px;
            //         padding-bottom: 86px;
            //     }
            // }
            // &--08 {
            //     @include pc {
            //         padding-top: 21px;
            //         padding-bottom: 41px;
            //     }
            // }
            .ttl {
                width: 245px;
                margin-bottom: auto;
                font-size: 16px;
                letter-spacing: 0.1em;
                font-weight: 500;
                line-height: 1.4;
                @include sp {
                    width: 170/750*100vw;
                    @include vw(26);
                    font-weight: 400;
                    line-height: 1.8;
                }
            }
            .txt {
                font-size: 16px;
                letter-spacing: 0.1em;
                line-height: 1.4;
                @include sp {
                    @include vw(26);
                    width: 498/750*100vw;
                }
            }
        }
    }
    &__access {
        padding: 172px 0 29px;
        background: url(../img/about/about_access_bg.png) no-repeat;
        background-size: cover;
        position: relative;
        @include sp {
            padding: 264/750*100vw 0 239/750*100vw;
            background: url(../img/about/sp/about_access_bg_sp.png) no-repeat;
            background-size: cover;
        }
    }
    &__accessBlcok {
        width: 930px;
        margin: 0 auto 110px;
        @include sp {
            width: 672/750*100vw;
            margin: 0 auto 160/750*100vw;
        }
    }
    &__accessDesc {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
        @include sp {
            flex-direction: column-reverse;
        }
        &--02 {
            @include pc {
                flex-direction: row-reverse !important;
            }
        }
        .img {
            @include sp {
                margin-bottom: 32/750*100vw;
            }
        }
        .ttl {
            display: block;
            font-size: 26px;
            font-weight: 500;
            margin-bottom: 26px;
            @include sp {
                @include vw(39);
                margin-bottom: 38/750*100vw;
            }
        }
        p {
            font-size: 16px;
            letter-spacing: 0.1em;
            line-height: 1.6;
            @include sp {
                @include vw(26);
            }
        }
        .list {
            display: block;
            margin: 36px 0 12px;
            padding-left: 22px;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.1em;
            line-height: 1.45;
            position: relative;
            @include sp {
                @include vw(26);
                font-weight: 400;
                padding-left: 33/750*100vw;
                margin: 43/750*100vw 0 15/750*100vw;
            }
            &::before {
                position: absolute;
                content: '';
                width: 16px;
                height: 2px;
                background: #098E75;
                left: 0;
                top: 50%;
                transform: rotateY(-50%);
                @include sp {
                    width: 24/750*100vw;
                    height: 3/750*100vw;
                }
            }
        }
        .list_txt {
            padding-left: 22px;
            font-size: 16px;
            width: 338px;
            display: block;
            position: relative;
            @include sp {
                @include vw(26);
                padding-left: 33/750*100vw;
                width:100%;
            }
            &::before {
                position: absolute;
                content: '';
                width: 2px;
                height: 2px;
                background: #098E75;
                left: 2px;
                top: 12px;
                @include sp {
                    width: 5/750*100vw;
                    height: 5/750*10vw;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 2/750*100vw;
                }
            }
        }
    }
}

.map {
    width: 100%;
    height: 400px;
    border-radius: 20px;
    overflow: hidden;
    @include sp {
        height: 400/750*100vw;
        border-radius: 20/750*100vw;
    }
}