@charset "utf-8";

.p-sitemap {
    padding: 96px 0 130px;
    @include sp {
        padding: 87/750*100vw 0 134/750*100vw;
    }
    &__top {
        padding-bottom: 80px;
        border-bottom: 1px solid #F6FAF3;
        margin-bottom: 69px;
        @include sp {
            padding-bottom: 128/750*100vw;
            border-bottom: 4/750*100vw solid #F6FAF3;
            margin-bottom: 67/750*100vw;
        }
    }
    &__ttl {
        width: 1120px;
        margin: 0 auto;
        @include sp {
            width: 670/750*100vw;
        }
        .ttl {
            display: block;
            font-size: 42px;
            letter-spacing: 0.1em;
            line-height: 1.2;
            color: #000;
            font-weight: 500;
            margin-bottom: 5px;
            @include sp {
                @include vw(50);
                margin-bottom: 6/750*100vw;
            }
        }
        .sub_ttl {
            font-family: 'Lato', sans-serif !important;
            font-size: 20px;
            letter-spacing: 0.04em;
            color: #098E75;
            @include sp {
                @include vw(28);
            }
        }
    }
    &__inner {
        width: 930px;
        margin: 0 auto;
        @include sp {
            width: 670/750*100vw;
        }
    }
    &__topItem {
      margin-bottom: 55px;
      @include pc {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        @include sp {
            margin-bottom: 91/750*100vw;
        }
        .item {
            display: block;
            font-size: 16px;
            letter-spacing: 0.1em;
            padding-left: 17px;
            position: relative;
            margin-bottom: 32px;
            @include sp {
                @include vw(26);
                margin-bottom: 50/750*100vw !important;
                padding-left: 31/750*100vw;
            }
            &:before {
                position: absolute;
                content: '';
                width: 8.5px;
                height: 8.5px;
                background: url(../img/sitemap/arrow.svg) no-repeat;
                background-size: cover;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                @include sp {
                    width: 15/750*100vw;
                    height: 15/750*100vw;
                }
            }
        }
    }

    &__midItem {
        display: flex;
        justify-content: space-between;
        margin-bottom: 81px;
        @include sp {
            display: block;
            margin-bottom: 95/750*100vw;
        }
    }
    .item {
        width: 445px;
        @include sp {
            width: 100%;
            margin-bottom: 91/750*100vw;
        }
    }
    .itemTtl {
        padding-bottom: 14px;
        margin-bottom: 20px;
        position: relative;
        @include sp {
            padding-bottom: 20/750*100vw;
            margin-bottom: 28/750*100vw;
        }
        &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 2px;
            background: #E8EAEA;
            bottom: 0;
            left: 0;
            @include sp {
                height: 4/750*100vw;
            }
        }
        &::after {
            position: absolute;
            content: '';
            width: 36px;
            height: 2px;
            background: #098E75;
            bottom: 0;
            left: 0;
            @include sp {
                width: 51/750*100vw;
                height: 4/750*100vw;
            }
        }
        a {
            display: block;
            font-size: 16px;
            letter-spacing: 0.1em;
            padding-left: 17px;
            position: relative;
            @include sp {
                @include vw(26);
                padding-left: 31/750*100vw;
            }
            &:before {
                position: absolute;
                content: '';
                width: 8.5px;
                height: 8.5px;
                background: url(../img/sitemap/arrow.svg) no-repeat;
                background-size: cover;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                @include sp {
                    width: 15/750*100vw;
                    height: 15/750*100vw;
                }
            }
        }
    }
    .box {
        display: flex;
        justify-content: space-between;
        @include sp {
            display: block;
        }
        a {
            display: block;
            width: 50%;
            font-size: 14px;
            letter-spacing: 0.1em;
            padding-left: 17px;
            position: relative;
            @include sp {
                @include vw(26);
                width: 100%;
                margin-bottom: 35/750*100vw;
                padding-left: 31/750*100vw;
            }
            &:last-child {
                @include sp {
                    margin-bottom: 0;
                }
            }
            &:before {
                position: absolute;
                content: '';
                width: 8.5px;
                height: 8.5px;
                background: url(../img/sitemap/arrow.svg) no-repeat;
                background-size: cover;
                left: 0;
                top: 12px;
                transform: translateY(-50%);
                @include sp {
                    width: 15/750*100vw;
                    height: 15/750*100vw;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
    .box02 {
        display: flex;
        justify-content: space-between;
        @include sp {
            display: block;
        }
        div {
            width: 50%;
            @include sp {
                width: 100%;
            }
            a {
                display: block;
                font-size: 14px;
                letter-spacing: 0.1em;
                padding-left: 17px;
                position: relative;
                margin-bottom: 5px;
                @include sp {
                    @include vw(26);
                    width: 100%;
                    margin-bottom: 31/750*100vw;
                    padding-left: 31/750*100vw;
                }
                &:before {
                    position: absolute;
                    content: '';
                    width: 8.5px;
                    height: 8.5px;
                    background: url(../img/sitemap/arrow.svg) no-repeat;
                    background-size: cover;
                    left: 0;
                    top: 12px;
                    transform: translateY(-50%);
                    @include sp {
                        width: 15/750*100vw;
                        height: 15/750*100vw;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }

    &__bottomItem {
        display: flex;
        justify-content: space-between;
        @include sp {
            display: block;
        }
        div {
            width: 445px;
            @include sp {
                width: 100%;
            }
            a {
                font-size: 16px;
                letter-spacing: 0.1em;
                padding-left: 17px;
                margin-bottom: 27px;
                position: relative;
                display: block;
                @include sp {
                    @include vw(26);
                    margin-bottom: 50/750*100vw !important;
                    padding-left: 31/750*100vw;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &:before {
                    position: absolute;
                    content: '';
                    width: 8.5px;
                    height: 8.5px;
                    background: url(../img/sitemap/arrow.svg) no-repeat;
                    background-size: cover;
                    left: 0;
                    top: 12px;
                    transform: translateY(-50%);
                    @include sp {
                        width: 15/750*100vw;
                        height: 15/750*100vw;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}