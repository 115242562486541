@charset "utf-8";

.p-error {
    padding: 240px 0 120px;
    text-align: center;
    @include sp {
        padding: 210/750*100vw 0 140/750*100vw;
    }
    &__ttl {
        font-size: 34px;
        font-weight: 400;
        margin-bottom: 75px;
        letter-spacing: .18em;
        text-align: center;
        position: relative;
        @include sp {
            width: 92%;
            margin: 0 auto 18.66667%;;
            font-size: 5.06667vw;
            font-weight: 700;
            
        }
        &::after {
            position: absolute;
            content: '';
            width: 65px;
            height: 2px;
            background: #098e75;
            left: 50%;
            bottom: -18px;
            transform: translateX(-50%);
            margin-top: 16px;
            @include sp {
                width: 8.53333vw;
                height: 3px;
                bottom: -3vw;
                margin-top: 3.33333vw;
            }
        }
    }
    &__txt {
        font-size: 16px;
        line-height: 1.8;
        @include sp {
            width: 92%;
            margin: 0 auto;
            font-size: 3.73333vw;
    line-height: 1.57143;
        }
    }
}