@charset "utf-8";

.p-factory {
    @include pc {
        padding-top: 72px;
    }
    @include sp {
        padding-top: 87/750*100vw;
    }
    &__kv {
        width: 100%;
        height: 443/1366*100vw;
        background: url(../img/factory/factory_bg.jpg) no-repeat;
        background-size: cover;
        position: relative;
        padding-top: 143/1366*100vw;
        @include sp {
            background: url(../img/factory/sp/factory_bg_sp.jpg) no-repeat;
            background-size: cover;
            height: 558/750*100vw;
            padding-top: 163/750*100vw;
        }
    }
    &__contTtl {
        text-align: center;
        margin-bottom: 40px;
        @include sp {
            margin-bottom: 70/750*100vw;
        }
        .ttl {
            display: block;
            font-size: 32px;
            letter-spacing: 0.1em;
            font-weight: 500;
            margin-bottom: 6px;
            line-height: 1.2;
            @include sp {
                @include vw(46);
                margin-bottom: 2/750*100vw;
            }
        }
        .sub_ttl {
            font-family: 'Lato', sans-serif !important;
            font-size: 14px;
            letter-spacing: 0.04em;
            color: #098E75;
            @include sp {
                @include vw(28);
            }
        }
    }
    &__intro {
        margin-bottom: 111px;
        @include sp {
            margin-bottom: 286/750*100vw;
        }
    }
    &__introInner {
        width: 930px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        @include sp {
            width: 672/750*100vw;
            display: block;
        }
    }
    &__introImgWrap {
        width: 530px;
        display: flex;
        justify-content: space-between;
        @include sp {
            width: 100%;
            margin-bottom: 58/750*100vw;
        }
        div {
            width: 260px;
            @include sp {
                width: 329/750*100vw;
            }
            img {
                margin-bottom: 10px;
                @include sp {
                    margin-bottom: 13/750*100vw;
                }
            }
            span {
                display: block;
                font-size: 14px;
                letter-spacing: 0.1em;
                @include sp {
                    @include vw(17);
                }
            }
        }
    }
    &__introTxt {
        width: 360px;
        font-size: 16px;
        letter-spacing: 0.1em;
        line-height: 2.25;
        @include sp {
            width: 100%;
            @include vw(26);
            line-height: 1.9;
        }
    }

    &__line {
        padding-bottom: 198/1366*100vw;
        position: relative;
        @include sp {
            padding-bottom: 430/750*100vw;
        }
        .introMask {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100vw;
            img {
                width: 100%;
            }
        }
    }
    &__lineTxt {
        font-size: 16px;
        letter-spacing: 0.1em;
        line-height: 1.6;
        text-align: center;
        margin-bottom: 40px;
        @include sp {
            @include vw(26);
            line-height: 1.9;
            width: 672/750*100vw;
            margin: 0 auto 51/750*100vw;
        }
    }
    &__lineTable {
        width: 930px;
        margin: 0 auto;
        @include sp {
            width: 672/750*100vw;
            margin: 0 auto;
            overflow-x: scroll;
        }
    }


    &__equipment {
        background: #F8FAFA;
        padding-bottom: 100px;
        @include sp {
            padding-top: 60/750*100vw;
            padding-bottom: 215/750*100vw;
        }
    }
    &__equipmentSlide {
        width: 930px;
        margin: 0 auto;
        @include sp {
            // width: 672/750*100vw;
            width: 732/750*100vw;
            
        }
    }
}

table {
    border: 1px solid #CCCCCC;
    border-bottom: none;
    @include sp {
        width: 1200/750*100vw;
    }
    .tr01 {
        background: #098E75;
        height: 67px;
        @include sp {
            height: 90/750*100vw;
        }
        th {
            vertical-align: middle;
            font-size: 16px;
            font-weight: 500;
            color: #fff;
            letter-spacing: 0.1em;
            border-right: 1px solid #CCCCCC;
            @include sp {
                @include vw(26);
            }
        }
    }
    .tr02 {
        border-bottom: 1px solid #CCCCCC;
        td {
            text-align: center;
            vertical-align: middle;
            border-right: 1px solid #CCCCCC;
            font-size: 15px;
            letter-spacing: 0.1em;
            @include sp {
                @include vw(26);
                text-align: left;
            }
            p {
                @include sp {
                    padding-left: 23/750*100vw;
                }
            }
            &:first-child {
                font-weight: 500;
                @include sp {
                    padding: 0;
                    text-align: center;
                }
            }
            &:nth-child(3) {
                text-align: left;
                p {
                    padding-left: 7px;
                    @include sp {
                        padding-left: 23/750*100vw;
                    }
                }
            }
            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                span {
                    font-size: 14px;
                    letter-spacing: 0.05em;
                    @include sp {
                        @include vw(24);
                    }
                }
                p {
                    @include sp {
                        padding-left: 23/750*100vw;
                    }
                }
                &:first-child {
                    height: 103px;
                    border-bottom: 1px solid #CCCCCC;
                    @include sp {
                        height: 200/750*100vw;
                    }
                }
                &:last-child {
                    height: 134px;
                    @include sp {
                        height: 265/750*100vw;
                    }
                }
            }
            &:first-child {
                background: #F5F5F5;
            }
        }
    }
    .tr03 {
        border-bottom: 1px solid #CCCCCC;
        td {
            text-align: center;
            vertical-align: middle;
            border-right: 1px solid #CCCCCC;
            font-size: 15px;
            letter-spacing: 0.1em;
            @include sp {
                @include vw(26);
                text-align: left;
            }
            &:first-child {
                font-weight: 500;
                @include sp {
                    text-align: center;
                }
            }
            &:nth-child(3) {
                text-align: left;
                padding-left: 7px;
                @include sp {
                    padding-left:0;
                }
            }
            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                p {
                    @include sp {
                        padding-left: 23/750*100vw;
                    }
                }
                span {
                    font-size: 14px;
                    letter-spacing: 0.05em;
                    @include sp {
                        @include vw(24);
                    }
                }
                &:first-child {
                    height: 89px;
                    border-bottom: 1px solid #CCCCCC;
                    @include sp {
                        height: 120/750*100vw;
                    }
                }
                &:nth-child(2) {
                    height: 131px;
                    border-bottom: 1px solid #CCCCCC;
                    @include sp {
                        height: 280/750*100vw;
                    }
                }
                &:last-child {
                    height: 87px;
                    @include sp {
                        height:120/750*100vw;
                    }
                }
            }
            &:first-child {
                background: #DDDDDD;
            }
        }
    }
}

// グラフの幅
.w1 {
    width: 141px;
    @include sp {
        width: 160.5/750*100vw;
    }
}
.w2 {
    width: 212px;
    @include sp {
        width: 270/750*100vw;
    }
}
.w3 {
    width: 211px;
    @include sp {
        width: 303/750*100vw;
    }
}
.w4 {
    width: 212px;
    @include sp {
        width: 215/750*100vw;
    }
}
.w5 {
    width: 154px;
    @include sp {
        width: 167.5/750*100vw;
    }
}