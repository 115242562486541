@charset "utf-8";

.p-news {
    padding-top: 96px;
    padding-bottom: 132px;
    @include sp {
        padding-top: 87/750*100vw;
        padding-bottom: 262/750*100vw;
    }
    &__top {
        padding-bottom: 80px;
        border-bottom: 2px solid #F6FAF3;
        margin-bottom: 32px;
        @include sp {
            padding-bottom: 128/750*100vw;
            border-bottom: 4/750*100vw solid #F6FAF3;
            margin-bottom: 72/750*100vw;
        }
    }
    &__ttl {
        width: 1120px;
        margin: 0 auto;
        @include sp {
            width: 670/750*100vw;
        }
        .ttl {
            display: block;
            font-size: 42px;
            letter-spacing: 0.1em;
            line-height: 1.2;
            color: #000;
            font-weight: 500;
            margin-bottom: 5px;
            @include sp {
                @include vw(50);
                margin-bottom: 6/750*100vw;
            }
        }
        .sub_ttl {
            font-family: 'Lato', sans-serif !important;
            font-size: 20px;
            letter-spacing: 0.04em;
            color: #098E75;
            @include sp {
                @include vw(28);
            }
        }
    }

    &__inner {
        width: 930px;
        margin: 0 auto;
        @include sp {
        width: 672/750*100vw;
        }
    }
    &__dropDpwnWrap {
        position: relative;
        margin-bottom: 85px;
        @include sp {
            margin-bottom: 80/750*100vw;
        }
    }
    &__dropDown {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 266px;
        height: 44px;
        border-radius: 22px;
        background: #fff;
        border: 1px solid #098E76;
        text-align: center;
        color: #098E75;
        font-size: 14px;
        z-index: 10;
        position: relative;
        @include sp {
            width: 344/750*100vw;
            height: 56/750*100vw;
            border-radius: 28/750*100vw;
            @include vw(24);
            text-align: left;
            padding-left: 25/750*100vw;
        }
        &::after {
            position: absolute;
            content: '';
            width: 8px;
            height: 8px;
            background: url(../img/common/drop_close.svg) no-repeat;
            background-size: cover;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
            @include sp {
                width: 15.9/750*100vw;
                height: 15.9/750*100vw;
                right: 30/750*100vw;
            }
        }
    }
    &__dropDownMenu {
        position: absolute;
        top: 5;
        padding: 24px 0 24px;
        background: #F8FAFA;
        width: 266px;
        z-index: 5;
        border-radius: 20px;
        letter-spacing: 0.1em;
        display: none;
        @include sp {
            width: 344/750*100vw;
            padding: 47/750*100vw 25/750*100vw 34/750*100vw;
            top: 28/750*100vw;
            border-radius: 0 0 28/750*100vw 28/750*100vw;
        }
        a {
            display: block;
            text-align: center;
            margin-bottom: 16px;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.1em;
            color: #098E75;
            @include sp {
                text-align: left;
                @include vw(24);
                margin-bottom: 24/750*100vw;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    

    &__topicList {
        width: 930px;
        margin: 0 auto 53px;
        @include sp {
            width: 673/750*100vw;
        }
        .item {
            display: flex;
            padding: 30px 25px;
            border-bottom: 1px solid #E8EAEA;
            @include sp {
                padding: 40/750*100vw 0;
                flex-wrap: wrap;
            }
            &:first-child {
                padding-top: 0;
            }
            .date {
                font-size: 16px;
                letter-spacing: 0.1em;
                margin-right: 32px;
                @include sp {
                    @include vw(24);
                    margin-right: 20/750*100vw;
                }
            }
            .tags {
                width: 152px;
                display: flex;
                justify-content: space-between;
                margin-right: 32px;
                align-items: center;
                @include sp {
                    width: 233/750*100vw;
                    margin-right: 0;
                }
                .news {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    width: 82px;
                    height: 19px;
                    font-size: 12px;
                    color: #4CA223;
                    border: 1px solid #4CA223;
                    border-radius: 9.5px;
                    @include sp {
                        width: 128/750*100vw;
                        height: 30/750*100vw;
                        @include vw(18);
                        border-radius: 15/750*100vw;
                    }
                }
                .new {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    width: 62px;
                    height: 19px;
                    font-size: 12px;
                    color: #E60012;
                    border: 1px solid #E60012;
                    border-radius: 9.5px;
                    @include sp {
                        width: 97/750*100vw;
                        height: 30/750*100vw;
                        @include vw(18);
                        border-radius: 15/750*100vw;
                    }
                }
            }
            .txt {
                font-size: 16px;
                letter-spacing: 0.1em;
                @include sp {
                    @include vw(26);
                    width: 100%;
                    margin-top: 24/750*100vw;
                }
            }
        }
    }

    &__pagenation {
        text-align: center;
        display: flex;
        justify-content: center;
        .prev {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 32px;
            @include sp {
                margin-right: 37/750*100vw;
                width: 40/750*100vw;
            }
        }
        .next {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 32px;
            @include sp {
                margin-left: 37/750*100vw;
                width: 40/750*100vw;
            }
        }
        a {
            display: block;
            width: 34px;
            height: 34px;
            font-size: 20px;
            color: #098E75;
            letter-spacing: 0.1em;
            margin-right: 20px;
            @include sp {
                width: 56/750*100vw;
                height: 56/750*100vw;
                margin-right: 27/750*100vw;
                @include vw(32);
            }
            &:last-child {
                margin-right: 0;
            }
        }
        span {
            display: block;
            width: 34px;
            height: 28px;
            margin-top: auto;
            font-size: 20px;
            color: #098E75;
            letter-spacing: 0.1em;
            margin-right: 20px;
            @include sp {
                width: 56/750*100vw;
                height: 44/750*100vw;
                margin-right: 27/750*100vw;
                @include vw(32);
            }
        }
        .active {
            color: #fff !important;
            background: #098E75;
            border-radius: 50%;
        }
        .sp-hidden {
            @include sp {
                display: none;
            }
        }
    }
}