.p-beverage {
  overflow-x: hidden;
  img {
    width: 100%;
  }
  &__detail1 {
    background-image: url(#{$imgPath}/beverage-business/bg07.png);
    background-repeat: repeat-x;
    background-size: contain;
    height: 40px;
    margin: 0 auto;
    position: relative;
    width: 100%;
    z-index: 2;
    @include pc {
      margin-top: -1px;
      // max-width: 2000px;
      min-width: 1200px;
    }
    @include sp {
      background-image: url(#{$imgPath}/beverage-business/sp/bg07_sp.png);
      height: 37/750*100vw;
    }
  }
  &__detail2 {
    background-image: url(#{$imgPath}/beverage-business/bg08.png);
    background-repeat: repeat-x;
    background-size: contain;
    height: 40px;
    margin: 0 auto;
    position: relative;
    width: 100%;
    z-index: 2;
    @include pc {
      // max-width: 2000px;
      min-width: 1200px;
    }
    @include sp {
      height: 40/750*100vw;
      margin-bottom: -1px;
    }
  }
}

.p-beverageKv {
  margin: 0 auto;
  position: relative;
  @include pc {
    // max-width: 2000px;
    min-width: 1200px;
  }
  &__logo {
    left: 30px;
    position: absolute;
    top: 22px;
    width: 270px;
    z-index: 10;
    @include sp {
      left: 25/750*100vw;
      top: 35/750*100vw;
      width: 239/750*100vw;
    }
  }
}


.p-beverageSec1 {
  background: #9FD998;
  margin: -2px auto;
  position: relative;
  z-index: 2;
  @include pc {
    // max-width: 2000px;
    min-width: 1200px;
  }
  @include sp {
    margin: -1px auto;
  }
  &__img01 {
    width: 329px;
    @include pc {
      position: absolute;
      left: 42px;
      top: 32px;
    }
    @include sp {
      margin: 0 auto;
      width: 722/750*100vw;
    }
  }
  &__img02 {
    position: absolute;
    right: 42px;
    top: 13px;
    width: 348px;
  }
  &__inner {
    margin: 0 auto;
    max-width: 1366px;
    padding-top: 110px;
    padding-bottom: 25px;
    position: relative;
    width: 100%;
    @include sp {
      padding: 85/750*100vw 0 55/750*100vw;
    }
  }
  &__ttl {
    margin: 0 auto 60px;
    width: 412px;
    @include sp {
      width: 491/750*100vw;
      margin-bottom: 52/750*100vw;
    }
  }
  &__txt {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.05em;
    line-height: 2.4;
    margin: 0 auto;
    text-align: center;
    @include sp {
      @include vw(30);
      line-height: 2;
      margin-bottom: 55/750*100vw;
    }
  }
}


.p-beverageSec2 {
  background-image: url(#{$imgPath}beverage-business/bg02.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  margin: 0 auto;
  padding-top: 258px;
  @include pc {
    // max-width: 2000px;
    min-width: 1200px;
  }
  @include sp {
    background-image: url(#{$imgPath}beverage-business/sp/bg02_sp.jpg);
    padding-top: 198/750*100vw;
  }
  &__inner {
    margin: 0 auto;
    max-width: 1366px;
    padding-bottom: 36px;
    position: relative;
    width: 100%;
    @include sp {
      padding-bottom: 192/750*100vw;
    }
  }
  &__ttl {
    margin: 0 0 52px 442/1366*100%;
    width: 608px;
    @include sp {
      margin: 0 0 58/750*100vw auto;
      width: 654/750*100vw;
    }
  }
  &__txt {
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto 47px;
    text-align: center;
    @include sp {
      @include vw(30);
      line-height: 1.73;
      margin-bottom: 260/750*100vw;
    }
  }
}
.p-beverageSec2Box {
  background: #fff;
  border-radius: 10px;
  margin: 0 auto 20px;
  padding-top: 30px;
  position: relative;
  width: 1088px;
  @include sp {
    border-radius: 10/750*100vw;
    margin-bottom: 40/750*100vw;
    padding-top: 30/750*100vw;
    width: 686/750*100vw;
  }
  &--2column {
    margin: 0 0 20px;
    width: 534px;
    @include sp {
      margin-bottom: 40/750*100vw;
      width: 686/750*100vw;
    }
  }
  &__flex {
    margin: 0 auto;
    width: 1088px;
    @include pc {
      display: flex;
      justify-content: space-between;
    }
    @include sp {
      width: 686/750*100vw;
    }
  }
  &__ttl {
    background-image: url(#{$imgPath}beverage-business/bg05.png);
    background-position: 0 0;
    background-size: contain;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0.12em;
    padding: 20px 0 20px 86px;
    position: relative;
    @include sp {
      @include vw(36);
      padding: 40/750*100vw 0 40/750*100vw 117/750*100vw;
    }
    &::before {
      content: "●";
      color: #44AF35;
      position: absolute;
      left: 45px;
      @include sp {
        left: 70/750*100vw;
      }
    }
    &--1 {
      margin-bottom: 24px;
      @include sp {
        margin-bottom: 48/750*100vw;
        padding: 20/750*100vw 0 20/750*100vw 117/750*100vw;
      }
    }
    &--2 {
      margin-bottom: 36px;
      @include sp {
        margin-bottom: 42/750*100vw;
      }
    }
    &--3 {
      margin-bottom: 14px;
      @include sp {
        margin-bottom: 58/750*100vw;
      }
    }
    &--4 {
      margin-bottom: 16px;
      @include pc {
        padding-left: 528px;
        &::before {
          left: 492px;
        }
      }
      @include sp {
        margin-bottom: 50/750*100vw;
      }
    }
  }
}
.p-beverageSec2Box1 {
  padding-bottom: 33px;
  @include sp {
    padding-top: 50/750*100vw;
    padding-bottom: 34/750*100vw;
  }
  &__container {
    margin-left: 50px;
    @include pc {
      display: flex;
    }
    @include sp {
      margin: 0 auto 75/750*100vw;
      width: 550/750*100vw;
    }
  }
  &__img01 {
    width: 306px;
    @include pc {
      position: absolute;
      right: 44px;
      top: 40px;
    }
    @include sp {
      margin: 0 auto;
      width: 622/750*100vw;
    }
  }
  &__img02 {
    left: 15px;
    position: absolute;
    top: -138px;
    width: 173px;
    @include sp {
      left: 168/750*100vw;
      top: -152/750*100vw;
      width: 214/750*100vw;
    }
  }
  &__img03 {
    position: absolute;
    right: 46px;
    top: -203px;
    width: 99px;
    @include sp {
      right: 180/750*100vw;
      top: -226/750*100vw;
      width: 113/750*100vw;
    }
  }
  &__txt01 {
    align-items: center;
    display: flex;
    margin-right: 32px;
    width: 364px;
    @include sp {
      margin: 0 auto 80/750*100vw;
      width: 551/750*100vw;
    }
  }
  &__txt02 {
    color: #44AF35;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 4px;
    @include sp {
      @include vw(28);
      margin-bottom: 8/750*100vw;
    }
  }
  &__txt03 {
    font-size: 15px;
    @include sp {
      @include vw(24);
    }
  }
  &__txtBox {
    &:not(:last-of-type) {
      margin-bottom: 14px;
      @include sp {
        margin-bottom: 26/750*100vw;
      }
    }
  }
}
.p-beverageSec2Box2 {
  padding-bottom: 40px;
  @include sp {
    padding-bottom: 70/750*100vw;
  }
  &__img {
    bottom: -35px;
    left: -66px;
    position: absolute;
    width: 130px;
    z-index: 2;
    @include sp {
      bottom: auto;
      left: 50/750*100vw;
      top: -195/750*100vw;
      width: 170/750*100vw;
    }
  }
  &__txt {
    margin-left: 72px;
    width: 402px;
    @include sp {
      margin: 0 auto;
      width: 588/750*100vw;
    }
  }
}
.p-beverageSec2Box3 {
  padding-bottom: 40px;
  @include sp {
    padding-bottom: 74/750*100vw;
  }
  &__txt01 {
    margin-bottom: 15px;
    @include sp {
      margin-bottom: 46/750*100vw;
    }
  }
  &__txt02 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.05em;
    @include sp {
      @include vw(26);
      line-height: 1.77;
      text-align: center;
    }
  }
  &__txtBox {
    margin-left: 54px;
    width: 396px;
    @include sp {
      margin: 0 auto;
      width: 588/750*100vw;
    }
  }
}
.p-beverageSec2Box4 {
  padding-bottom: 50px;
  margin-bottom: 0;
  @include sp {
    padding-bottom: 67/750*100vw;
  }
  &__img01 {
    width: 368px;
    @include pc {
      left: 65px;
      position: absolute;
      top: 30px;
    }
    @include sp {
      margin: 0 auto;
      width: 554/750*100vw;
    }
  }
  &__img02 {
    position: absolute;
    right: 16px;
    top: 58px;
    width: 178px;
    @include sp {
      right: 23/750*100vw;
      top: -82/750*100vw;
      width: 184/750*100vw;
    }
  }
  &__txt01 {
    margin-bottom: 24px;
    @include sp {
      margin: 0 auto 52/750*100vw;
      width: 529/750*100vw;
    }
  }
  &__txt02 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.05em;
    margin-bottom: 12px;
    @include sp {
      @include vw(26);
      line-height: 1.77;
      margin-bottom: 28/750*100vw;
      text-align: center;
    }
  }
  &__txt03 {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.05em;
    @include sp {
      @include vw(22);
      text-align: center;
    }
  }
  &__txtBox {
    margin-right: 202px;
    margin-left: auto;
    width: 390px;
    @include sp {
      margin: 0 auto 37/750*100vw;
      width: 529/750*100vw;
    }
  }
}


.p-beverageSec3 {
  background-image: url(#{$imgPath}beverage-business/bg03.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  margin: 0 auto;
  &.is-change {
    @include pc {
      background-image: url(#{$imgPath}beverage-business/bg03-2.jpg);
    }
    @include sp {
      background-image: url(#{$imgPath}beverage-business/sp/bg03-2_sp.jpg);
    }
  }
  @include pc {
    // max-width: 2000px;
    min-width: 1200px;
  }
  @include sp {
    background-image: url(#{$imgPath}beverage-business/sp/bg03_sp.jpg);
    margin: -1px auto 0;
  }
  &__img01 {
    width: 384px;
    @media print, screen and (max-width: 1366px) {
      width: 384/1366*100vw;
    }
    @media print, screen and (max-width: 1200px) {
      width: 384/1366*1200px;
    }
    @include pc {
    left: 5px;
    position: absolute;
    top: 160px;
    }
    @include sp {
      margin: 0 auto 35/750*100vw;
      width: 618/750*100vw;
    }
  }
  &__img02 {
    position: absolute;
    right: 60px;
    top: 308px;
    width: 260px;
    @media print, screen and (max-width: 1366px) {
      width: 260/1366*100vw;
    }
    @media print, screen and (max-width: 1200px) {
      width: 260/1366*1200px;
    }
    @include sp {
      top: -56/750*100vw;
      right: 42/750*100vw;
      width: 260/750*100vw;
    }
  }
  &__img03 {
    bottom: 88px;
    left: 56px;
    position: absolute;
    width: 254px;
    @include sp {
      bottom: 20/750*100vw;
      left: 107/750*100vw;
      width: 225/750*100vw;
    }
  }
  &__img04 {
    bottom: 88px;
    position: absolute;
    right: 40px;
    width: 315px;
    @include sp {
      bottom: 20/750*100vw;
      right: 72/750*100vw;
      width: 280/750*100vw;
    }
  }
  &__inner {
    margin: 0 auto;
    max-width: 1366px;
    padding: 222px 0 168px;
    position: relative;
    width: 100%;
    @include sp {
      padding: 295/750*100vw 0 300/750*100vw;
    }
  }
  &__ttl {
    margin: 0 0 44px 414px;
    width: 664px;
    @media print, screen and (max-width: 1366px) {
      margin-left: 414/1366*100vw;
      width: 664/1366*100vw;
    }
    @media print, screen and (max-width: 1200px) {
      margin-left: 414/1366*1200px;
      width: 664/1366*1200px;
    }
    @include sp {
      margin: 0 0 54/750*100vw auto;
      width: 688/750*100vw;
    }
  }
  &__txt01 {
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto 50px;
    line-height: 2;
    letter-spacing: 0.05em;
    text-align: center;
    width: 540px;
    @media print, screen and (max-width: 1366px) {
      font-size: 20/1366*100vw;
      width: 540/1366*100vw;
    }
    @media print, screen and (max-width: 1200px) {
      font-size: 20/1366*1200px;
      width: 540/1366*1200px;
    }
    @include sp {
      @include vw(30);
      margin-bottom: 28/750*100vw;
      width: 680/750*100vw;
    }
  }
  &__txt02 {
    margin: 0 auto;
    width: 506px;
    @include sp {
      width: 626/750*100vw;
    }
  }
}
.p-beverageSec3Tab {
  margin: 0 auto 100px;
  width: 1088px;
  @include sp {
    margin-bottom: 60/750*100vw;
    width: 686/750*100vw;
  }
  &__list {
    display: flex;
    justify-content: space-between;
  }
  &__item {
    background: #fff;
    border-radius: 20px 20px 0 0;
    cursor: pointer;
    padding: 30px 40px;
    width: 536px;
    @include sp {
      border-radius: 20/750*100vw 20/750*100vw 0 0;
      margin-bottom: -1px;
      padding: 34/750*100vw 38/750*100vw 38/750*100vw;
      width: 335/750*100vw;
    }
    &--ocha {
      &.active {
        background: #44af35;
      }
    }
    &--pet {
      &.active {
        background: #009c87;
      }
    }
  }
  &__itemTxt {
    color: #000;
    font-size: 26px;
    font-weight: bold;
    @include sp {
      @include vw(30);
    }
    &::before {
      content: "●";
      margin-right: 13px;
    }
    .p-beverageSec3Tab__item--pet & {
      &::before {
        color: #009C87;
      }
    }
    .p-beverageSec3Tab__item--ocha & {
      &::before {
        color: #44AF35;
      }
    }
    .p-beverageSec3Tab__item.active & {
      color: #fff;
      &::before {
        color: #fff;
      }
    }
  }
}
.p-beverageSec3TabBox {
  background: #fff;
  border-radius: 0 0 10px 10px;
  display: none;
  padding: 46px 84px 50px 76px;
  @include sp {
    padding: 50/750*100vw 40/750*100vw 74/750*100vw;
  }
  &.active {
    display: block;
  }
  &__item {
    position: relative;
    @include pc {
      display: flex;
      justify-content: space-between;
    }
    @include sp {
      width: 608/750*100vw;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &:not(:nth-last-child(2)) {
      margin-bottom: 60px;
      @include pc {
        &::before {
          background-size: contain;
          background-repeat: no-repeat;
          content: "";
          height: 18px;
          position: absolute;
          bottom: -38px;
          left: 138px;
          width: 17px;
          .p-beverageSec3TabBox1 & {
            background-image: url(#{$imgPath}beverage-business/arrow01.png);
          }
          .p-beverageSec3TabBox2 & {
            background-image: url(#{$imgPath}beverage-business/arrow02.png);
          }
        }
      }
      @include sp {
        margin-bottom: 75/750*100vw;
      }
    }
  }
  &__itemContents {
    width: 578px;
    @include sp {
      width: 100%;
    }
  }
  &__itemImg {
    width: 294px;
    @include sp {
      margin-bottom: 36/750*100vw;
      width: 100%;
    }
  }
  &__itemTtl {
    align-items: center;
    display: flex;
    font-size: 28px;
    line-height: 1;
    margin-bottom: 34px;
    position: relative;
    &::before {
      background-image: url(#{$imgPath}beverage-business/bg06.png);
      bottom: 0;
      content: "";
      height: 24px;
      left: 0;
      position: absolute;
      width: 100%;
    }
    .p-beverageSec3TabBox2 & {
      &::before {
        background-image: url(#{$imgPath}beverage-business/bg06-2.png);
      }
    }
    @include sp {
      @include vw(40);
      margin-bottom: 26/750*100vw;
    }
  }
  &__itemTtlNum {
    font-family: 'Poppins', sans-serif;
    font-size: 50px;
    margin-right: 30px;
    @include sp {
      @include vw(100);
      margin-right: 38/750*100vw;
    }
    &--ocha {
      color: #44af35;
    }
    &--pet {
      color: #009C87;
    }
  }
  &__itemTtlS {
    font-size: 18px;
    margin-left: 14px;
    @include sp {
      @include vw(28);
      display: block;
      margin-left: 0;
      margin-top: 8/750*100vw;
    }
  }
  &__TtlTxt {
    @include sp {
      display: flex;
      flex-direction: column;
    }
  }
  &__itemTxt {
    color: #5a5a5a;
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 2;
    &--caution {
      font-size: 14px;
    }
    @include sp {
      @include vw(26);
      line-height: 1.7;
    }
  }
  &__toTop {
    width: 136px;
    margin: 50px 0 0 auto;
    @include sp {
      margin-top: 70/750*100vw;
      width: 273/750*100vw;
    }
  }
}
.p-beverageSec3TabBox1 {
  border-top: 12px solid #44af35;
  @include sp {
    border-top: 12/750*100vw solid #44af35;
  }
  &__ttl {
    margin-bottom: 70px;
    width: 760px;
    @include sp {
      margin: 0 auto 60/750*100vw;
      width: 436/750*100vw;
    }
  }
}
.p-beverageSec3TabBox2 {
  border-top: 12px solid #009c87;
  @include sp {
    border-top: 12/750*100vw solid #009c87;
  }
  &__ttl {
    margin-bottom: 70px;
    width: 843px;
    @include sp {
      width: 607/750*100vw;
      margin-bottom: 84/750*100vw;
    }
  }
}


.p-beverageSec4 {
  background: #D0EAED;
  margin: -40px auto;
  @include pc {
    // max-width: 2000px;
    min-width: 1200px;
  }
  @include sp {
    margin: -37/750*100vw auto -80/750*100vw;
  }
  &__container {
    margin: 0 auto 152px;
    @include pc {
      display: flex;
      justify-content: space-between;
      width: 1120px;
    }
    @include sp {
      margin-bottom: 82/750*100vw;
    }
  }
  &__img {
    bottom: -58px;
    left: 190px;
    position: absolute;
    width: 404px;
    @include sp {
      bottom: -6/750*100vw;
      left: 156/750*100vw;
      width: 404/750*100vw;
    }
  }
  &__inner {
    margin: 0 auto;
    max-width: 1366px;
    padding: 116px 0 135px;
    position: relative;
    width: 100%;
    @include sp {
      padding: 138/750*100vw 0 250/750*100vw;
    }
  }
  &__ttl {
    margin: 0 auto 50px;
    width: 803px;
    @include sp {
      margin: 0 auto 46/750*100vw;
      width: 616/750*100vw;
    }
  }
  &__ttlWrap {
    margin-bottom: 70px;
    @include sp {
      margin-bottom: 42/750*100vw;
    }
  }
  &__txt01 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.05em;
    text-align: center;
    @include sp {
      @include vw(27);
      margin: 0 auto;
    }
  }
  &__txt02 {
    color: #075313;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
    @include sp {
      @include vw(44);
      margin-bottom: 24/750*100vw;
    }
    span {
      display: inline-block;
      position: relative;
      &:not(:last-child) {
        margin-right: 58px;
        @include sp {
          margin-right: 70/750*100vw;
        }
        &::before {
          background-image: url(#{$imgPath}beverage-business/arrow01.png);
          background-repeat: no-repeat;
          background-size: contain;
          content: "";
          height: 18px;
          position: absolute;
          right: -40px;
          top: 30%;
          transform: rotate(-90deg);
          width: 18px;
          @include sp {
            height: 30/750*100vw;
            right: -52/750*100vw;
            top: 24/750*100vw;
            width: 30/750*100vw;
          }
        }
      }
    }
  }
  &__txt03 {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.05em;
    @include sp {
      @include vw(28);
    }
  }
  &__txt04 {
    margin: 0 auto 0 663px;
    width: 540px;
    @include sp {
      margin: 0 auto;
      width: 710/750*100vw;
    }
  }
  &__txtWrap {
    display: none;
    margin-top: 65px;
    width: 460px;
    @include sp {
      margin: 46/750*100vw auto;
      width: 678/750*100vw;
    }
    &.is-active {
      display: block;
    }
  }
}
.p-beverageSec4Slider {
  width: 576px;
  padding: 0 20px;
  position: relative;
  @include sp {
    width: 729/750*100vw;
    padding: 0 28/750*100vw;
  }
  &__item {
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
    @include pc {
      height: 348px;
      width: 536px;
    }
    &::before {
      background-image: url(#{$imgPath}/beverage-business/btn01.png);
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      height: 58px;
      left: 50%;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 58px;
      z-index: 100;
      @include sp {
        height: 116/750*100vw;
        width: 116/750*100vw;
      }
    }
    &.is-active {
      &::before {
        display: none;
      }
    }
    video {
      width: 100%;
    }
  }
  &__next {
    background: none;
    &::before {
      background-image: url(#{$imgPath}/beverage-business/arrow04.png);
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      height: 49px;
      position: absolute;
      right: -5px;
      width: 49px;
      @include sp {
        height: 75/750*100vw;
        width: 75/750*100vw;
      }
    }
  }
  &__prev {
    background: none;
    &::before {
      background-image: url(#{$imgPath}/beverage-business/arrow03.png);
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      height: 49px;
      position: absolute;
      left: -5px;
      width: 49px;
      @include sp {
        height: 75/750*100vw;
        width: 75/750*100vw;
      }
    }
  }
  &__pagination {
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    @include sp {
      display: none;
    }
    span {
      margin: 0 9px;
    }
  }
  &__wrapper {
    position: relative;
    .swiper-pagination-bullet {
      background-color: #fff;
      border: none;
      height: 14px;
      width: 14px;
    }
    .swiper-pagination-bullet-active {
      background-color: #44AF35;
    }
  }
  
}


.p-beverageFooter {
  background: #44af35;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  @include pc {
    // max-width: 2000px;
    min-width: 1200px;
    margin: -5px auto 0;
  }
  &__btn {
    background: #fff;
    border-radius: 8px;
    display: block;
    @include pc {
      width: 518px;
    }
    @include sp {
      border-radius: 8/750*100vw;
    }
    &:not(:last-of-type) {
      @include sp {
        margin-bottom: 26/750*100vw;
      }
    }
    &--1 {
      padding: 38px 95px;
      @include sp {
        padding: 50/750*100vw 126/750*100vw;
      }
    }
    &--2 {
      padding: 38px 24px;
      @include sp {
        padding: 50/750*100vw 32/750*100vw;
      }
    }
  }
  &__btnWrap {
    margin: 0 auto 68px;
    width: 1088px;
    @include pc {
      display: flex;
      justify-content: space-between;
    }
    @include sp {
      margin-bottom: 70/750*100vw;
      width: 685/750*100vw;
    }
  }
  &__inner {
    padding: 78px 0 30px;
    @include sp {
      padding: 93/750*100vw 0 37/750*100vw;
    }
  }
  &__txt {
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.04em;
    text-align: center;
    @include sp {
      @include vw(20);
    }
  }
}
