@charset "utf-8";

.p-newsDetails {
    padding-bottom: 132px;
    padding-top: 72px;
    @include sp {
        padding-top: 87/750*100vw;
        padding-bottom: 238/750*100vw;
    }
    &__top {
        padding-bottom: 80px;
        border-bottom: 2px solid #F6FAF3;
        margin-bottom: 74px;
        @include sp {
            padding-bottom: 65/750*100vw;
            border-bottom: 4/750*100vw solid #F6FAF3;
            margin-bottom: 74/750*100vw;
        }
    }
    &__topInner {
        width: 1120px;
        margin: 0 auto;
        @include sp {
            width: 672/750*100vw;
        }
    }
    &__ttl {
        font-size: 42px;
        font-weight: 500;
        letter-spacing: 0.1em;
        margin-bottom: 24px;
        line-height: 1.3;
        @include sp {
            @include vw(50);
            letter-spacing: 0.07em;
            margin-bottom: 74/750*100vw;
        }
    }
    &__Data {
        display: flex;
        .data {
            display: block;
            font-size: 16px;
            letter-spacing: 0.1em;
            margin-right: 32px;
            @include sp {
                @include vw(24);
                margin-right: 20/750*100vw;
            }
        }
    }

    &__cont {
        width: 930px;
        margin: 0 auto 93px;
        @include sp {
            width: 672/750*100vw;
            margin: 0 auto 100/750*100vw;
        }
    }
    &__healine {
        font-size: 22px;
        letter-spacing: 0.08em;
        padding-bottom: 7px;
        margin-bottom: 30px;
        position: relative;
        @include sp {
            @include vw(32);
            padding-bottom: 7/750*100vw;
            margin-bottom: 40/750*100vw;
        }
        &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 2px;
            background: #E8EAEA;
            bottom: -9px;
            @include sp {
                height: 4/750*100vw;
                bottom: -9/750*100vw;
            }
        }
        &::after {
            position: absolute;
            content: '';
            width: 36px;
            height: 2px;
            background: #098E75;
            bottom: -9px;
            left: 0;
            @include sp {
                width: 51/750*100vw;
                height: 4/750*100vw;
                bottom: -9/750*100vw;
            }
        }
    }

    &__txt {
        margin-bottom: 75px;
        font-size: 16px;
        line-height: 1.3;
        letter-spacing: 0.1em;
        @include sp {
            @include vw(26);
            line-height: 1.9;
            margin-bottom: 147/750*100vw;
        }
    }

    &__img {
        @include sp {
            width: 100%;
        }
    }

}




.tags {
    width: 152px;
    display: flex;
    justify-content: space-between;
    margin-right: 32px;
    align-items: center;
    @include sp {
        width: 233/750*100vw;
        margin-right: 0;
    }
    .news {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: 82px;
        height: 19px;
        font-size: 12px;
        color: #4CA223;
        border: 1px solid #4CA223;
        border-radius: 9.5px;
        @include sp {
            width: 128/750*100vw;
            height: 30/750*100vw;
            @include vw(18);
            border-radius: 15/750*100vw;
        }
    }
    .new {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: 62px;
        height: 19px;
        font-size: 12px;
        color: #E60012;
        border: 1px solid #E60012;
        border-radius: 9.5px;
        @include sp {
            width: 97/750*100vw;
            height: 30/750*100vw;
            @include vw(18);
            border-radius: 15/750*100vw;
        }
    }
}