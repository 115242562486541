@charset "utf-8";

.p-top {
    &__kv {
        width: 100%;
        height: 700/1366*100vw;
        background: url(../img/top/kv.jpg) no-repeat;
        background-size: cover;
        position: relative;
        margin-bottom: 80px;
        @include sp {
            height: 999/750*100vw;
            background: url(../img/top/sp/kv_sp.jpg) no-repeat;
            background-size: cover;
            margin-bottom: 40/750*100vw;
        }
    }
    &__kvTtl {
        padding-top: 230/1366*100vw;
        margin-left: 123/1366*100vw;
        @include sp {
            padding-top: 233/750*100vw;
            margin-left: 38/750*100vw;
            width: 537/750*100vw;
        }
    }

    &__news {
        margin-bottom: 120px;
        @include sp {
            margin-bottom: 210/750*100vw;
        }
    }
    &__newsInner {
        width: 1120px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        @include sp {
            width: 674/750*100vw;
            display: block;
        }
    }
    &__newsTtl {
        @include sp {
            text-align: center;
            margin-bottom: 56/750*100vw;
        }
        .ttl {
            font-size: 32px;
            margin-bottom: 6px;
            line-height: 1.34;
            display: block;
            letter-spacing: 0.1em;
            font-weight: 400;
            @include sp {
                @include vw(46);
                margin-bottom: 2/750*100vw;
            }
        }
        .subTtl {
            font-family: 'Lato', sans-serif;
            font-size: 14px;
            letter-spacing: 0.04em;
            color: #098E75;
            @include sp {
               @include vw(28);
            }
        }
    }
    &__newsList {
        width: 930px;
        @include sp {
            width: 100%;
        }
    }
    &__newsItem {
        height: 51px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 1px solid #E8EAEA;
        @include sp {
            height: auto;
            display: block;
        }
        a {
            display: flex;
            align-items: center;
            position: relative;
            @include sp {
                display: block;
                margin-bottom: 40/750*100vw;
            }
            .date {
                font-size: 16px;
                letter-spacing: 0.1;
                @include sp {
                    display: block;
                    @include vw(24);
                    margin-top: 24/750*100vw;
                }
            }
            .badge {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 62px;
                height: 16px;
                color: #E60012;
                border: 1px solid #E60012;
                margin-left: 16px;
                font-size: 12px;
                border-radius: 8px;
                text-align: center;
                @include sp {
                    @include vw(18);
                    width: 97/750*100;
                    height: 30/750*100vw;
                    position: absolute;
                    top: 0.4vw;
                    left: 135/750*100vw;
                }
            }
            .text {
                position: absolute;
                left: 177px;
                font-size: 16px;
                letter-spacing: 0.1em;
                @include sp {
                    @include vw(26);
                    position: static;
                    display: block;
                }
            }
        }
        div {
            display: flex;
            align-items: center;
            position: relative;
            @include sp {
                display: block;
                margin-bottom: 40/750*100vw;
            }
            .date {
                font-size: 16px;
                letter-spacing: 0.1;
                @include sp {
                    display: block;
                    @include vw(24);
                    margin-top: 24/750*100vw;
                }
            }
            .badge {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 62px;
                height: 16px;
                color: #E60012;
                border: 1px solid #E60012;
                margin-left: 16px;
                font-size: 12px;
                border-radius: 8px;
                text-align: center;
                @include sp {
                    @include vw(18);
                    width: 97/750*100;
                    height: 30/750*100vw;
                    position: absolute;
                    top: 0.4vw;
                    left: 135/750*100vw;
                }
            }
            .text {
                position: absolute;
                left: 177px;
                font-size: 16px;
                letter-spacing: 0.1em;
                @include sp {
                    @include vw(26);
                    position: static;
                    display: block;
                }
            }
        }
    }
    &__newsBtn  {
        margin-left: auto;
        margin-top: 20px;
        @include sp {
            margin-top: 40/750*100vw;

        }
    }

    &__contents {
        padding-bottom: 302px;
        width: 100%;
        height: cover;
        background: url(../img/top/cont_bg.png) no-repeat;
        background-size: cover;
        background-position: bottom;
        position: relative;
        @include sp {
            padding-bottom: 270/750*100vw;
            background: url(../img/top/sp/cont_bg_sp.png) no-repeat;
            background-size: cover;
            margin-bottom: 60/750*100vw;
        }
    }
    &__contBlock {
        position: relative;
        height: 625px;
        @include sp {
            position: relative !important;
            width: 672/750*100vw;
            height: auto;
            margin: 0 auto;
        }
        &--01 {
            margin-bottom: 120px;
            @include sp {
                margin-bottom: 120/750*100vw !important;
                z-index: 100;
            }
            .img {
                right: 0;
                top: 0;
                @include sp {
                    position: static !important;
                    width: 672/750*100vw;
                }
                img {
                    @include sp {
                        width: 672/750*100vw;
                    }
                }
            }
            .txt_block {
                top: 120px;
                right: 615px;
                @include pcMin {
                    right: 515px;
                }
                @include sp {
                    top: 0;
                    right: 0;
                }
            }
        }
        &--02 {
            @include sp {
                z-index: 100;
            }
            img {
                left: 0;
                top: 0;
                @include sp {
                    position: static !important;
                    width: 672/750*100vw;
                }
                img {
                    @include sp {
                        width: 672/750*100vw;
                    }
                }
            }
            .txt_block {
                top: 120px;
                left: 578px;
                @include pcMin {
                    left: 520px;
                }
                @include sp {
                    top: 0;
                    right: 0;
                }
            }
        }
        .img {
            position: absolute;
            z-index: 100;
            width: 768px;
            @include sp {
                position: static !important;
            }
        }
        .txt_block {
            position: absolute;
            z-index: 150;
            background: #fff;
            width: 665px;
            padding: 65px 0 71px;
            border-radius: 20px;
            @include sp {
                position: static !important;
                width: 100%;
                padding: 62/750*100vw 0 80/750*100vw;
                border-radius: 0 0 40/750*100vw 40/750*100vw;
            }
            .inner {
                width: 521px;
                margin: 0 auto;
                @include sp {
                    width: 528/750*100vw;
                }
            }
            .ttl {
                font-size: 32px;
                letter-spacing: 0.1em;
                margin-bottom: 6px;
                @include sp {
                    display: block;
                    @include vw(46);
                    margin-bottom: 2/750*100vw;
                    text-align: center;
                }
            }
            .sub_ttl {
                // margin-bottom: 30px;
                // font-family: 'Lato', sans-serif !important;
                // font-size: 14px;
                // letter-spacing: 0.04em;
                // color: #098E75;
                // display: block;
                @include sp {
                    @include vw(28);
                    text-align: center;
                    margin: 0 auto 57/750*100vw;
                }
                &--01 {
                    // font-family: 'Lato', sans-serif !important;
                    // font-size: 14px;
                    // letter-spacing: 0.04em;
                    // color: #098E75;
                    // @include sp {
                    //     @include vw(28);
                    //     text-align: center;
                    //     margin: 0 auto 57/750*100vw;
                    // }
                }
                &--02 {
                    // font-family: 'Lato', sans-serif !important;
                    // font-size: 14px;
                    // letter-spacing: 0.04em;
                    // color: #098E75;
                    // @include sp {
                    //     text-align: center;
                    //     margin: 0 auto 57/750*100vw;
                    // }
                    img {
                        @include sp {
                            width: 306/750*100vw;
                        }
                    }
                }
            }
            .txt {
                font-size: 16px;
                letter-spacing: 0.1em;
                line-height: 2.25;
                margin-bottom: 48px;
                @include sp {
                    @include vw(26);
                    line-height: 1.9;
                    margin-bottom: 71/750*100vw;
                }
            }
            .btn {
                margin-left: auto;
                @include sp {
                    margin: 0 auto;
                }
            }
        }
    }
    &__contMaskTop {
        position: absolute;
        width: 100%;
        top: 0;
        z-index: 50;
        @include pcMin {
            top: -5px;
        }
        img {
            width: 100%;
        }
    }
    &__contMask {
        position: absolute;
        width: 100%;
        bottom: 0;
        z-index: 50;
        img {
            width: 100%;
        }
    }

    &__products {
        width: 1120px;
        margin: 0 auto 110px;
        @include sp {
            width: 100vw;
            margin: 0 auto 140/750*100vw;
        }
    }
    &__productsTtl {
        text-align: center;
        margin-bottom: 40px;
        padding-top: 40px;
        @include sp {
            padding-top: 40/750*100vw;
            margin-bottom: 67/750*100vw;
        }
        .ttl {
            font-size: 32px;
            letter-spacing: 0.1em;
            margin-bottom: 6px;
            font-weight: 400;
            @include sp {
                @include vw(46);
                margin-bottom: 2/750*100vw;
            }
        }
        .sub_ttl {
            display: block;
            font-family: 'Lato', sans-serif;
                font-size: 14px;
                letter-spacing: 0.04em;
                color: #098E75;
            @include sp {
                @include vw(28);
                text-align: center;
                margin: 0 auto;
            }
        }
    }
    &__productsTxt {
        text-align: center;
        margin-bottom: 63px;
        font-size: 16px;
        letter-spacing: 0.1em;
        line-height: 1.37;
        list-style: none;
        @include sp {
            @include vw(26);
            margin: 0 auto 71/750*100vw;
            line-height: 1.9;
            width: 672/750*100vw;
        }
    }
    &__productsLinkWrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
        @include sp {
            width: 672/750*100vw;
            display: block;
            margin: 0 auto 131/750*100vw;
        }
    }
    &__productsLinkItem {
        &--02 {}
        .btn {
            display: flex;
            height: 58px;
            width: auto;
            flex-direction: column;
            justify-content: center;
            font-size: 22px;
            letter-spacing: 0.1em;
            line-height: 1.2;
            text-align: center;
            margin-top: 20px;
            position: relative;
            @include sp {
                height: 92/750*100vw;
                @include vw(32);
                line-height: 1.5;
                width: 100%;
            }
        }
        &--01{
            @include sp {
                margin-bottom: 80/750*100vw;
            }
            .btn {
                &:before {
                    position: absolute;
                    content: '';
                    width: 28px;
                    height: 28px;
                    border: 1px solid #098E75;
                    border-radius: 50%;
                    right: 54px;
                    top: 14px;
                    @include sp {
                        width: 46/750*100vw;
                        height: 46/750*100vw;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 48/750*100vw;
                    }
                }
                &::after {
                    position: absolute;
                    content: '';
                    width: 20px;
                    height: 4px;
                    background: url(../img/common/arrow.svg) no-repeat;
                    background-size: cover;
                    top: 25px;
                    right: 59px;
                    transition:0.4s;
                    @include sp {
                        width: 28/750*100vw;
                        height: 5/750*100vw;
                        background: url(../img/common/arrow.svg) no-repeat;
                        background-size: 28/750*100vw 5/750*100vw;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 57/750*100vw;
                    }
                }
                // &:hover::after {
                //     right: 49px;
                //     @include sp {
                //         right: 47/750*100vw;
                //     }
                // }
            }
        }
        &--02{
            .btn {
                &:before {
                    position: absolute;
                    content: '';
                    width: 28px;
                    height: 28px;
                    border: 1px solid #098E75;
                    border-radius: 50%;
                    right: 191px;
                    top: 14px;
                    @include sp {
                        width: 46/750*100vw;
                        height: 46/750*100vw;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 48/750*100vw;
                    }
                }
                &::after {
                    position: absolute;
                    content: '';
                    width: 20px;
                    height: 4px;
                    background: url(../img/common/arrow.svg) no-repeat;
                    background-size: cover;
                    top: 25px;
                    right: 196px;
                    transition:0.4s;
                    @include sp {
                        width: 28/750*100vw;
                        height: 5/750*100vw;
                        background: url(../img/common/arrow.svg) no-repeat;
                        background-size: 28/750*100vw 5/750*100vw;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 57/750*100vw;
                    }
                }
                // &:hover::after {
                //     right: 186px;
                //     @include sp {
                //         right: 47/750*100vw;
                //     }
                // }
            }
        }
    }

    &__productsGroup {
        width: 100%;
        padding: 60px 0;
        margin-bottom: 60px;
        background: #F6FAF3;
        border-radius: 20px;
        @include sp {
            width: 672/750*100vw;
            padding: 60/750*100vw 0 80/750*100vw;
            margin: 0 auto 80/750*100vw;
        }
    }
    &__productsGroupInner {
        width: 1000px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        @include sp {
            width: 552/750*100vw;
            display: block;
        }
    }
    &__productsGroupTextWrap {
        width: 408px;
        @include sp {
            width: 100%;
        }
        .ttl {
            font-size: 24px;
            letter-spacing: 0.1em;
            font-weight: 500;
            margin-bottom: 19px;
            @include sp {
                @include vw(36);
                margin-bottom: 35/750*100vw;
                text-align: center;
            }
        }
        .txt {
            font-size: 16px;
            letter-spacing: 0.1em;
            margin-bottom: 37px;
            font-weight: 400;
            @include sp {
                @include vw(26);
                margin-bottom: 51/750*100vw;
                text-align: center;
                line-height: 1.8;
            }
        }
        .btn {
            width: 251px;
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 40px;
            background: #fff;
            border: 1px solid #098E75;
            border-radius: 30px;
            position: relative;
            @include sp {
                width: 500/750*100vw;
                height: 100/750*100vw;
                padding-left: 0;
                // padding-top: 1vw;
                // text-align: center;
                padding-left: 50/414*100vw;
                margin: 0 auto 80/750*100vw;
            }
            &::after {
                position: absolute;
                content: '';
                width: 14px;
                height: 11px;
                background: url(../img/common/blank.svg) no-repeat;
                background-size: cover;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                @include sp {
                    width: 30/750*100vw;
                    height: 24/750*100vw;
                    right: 31/750*100vw;
                }
            }
            img {
                width: 157px;
                @include sp {
                    width: 261/750*100vw;
                    // margin: 0 auto;
                }
            }
        }
    }
    &__productsBtn {
        margin: 0 auto;
        padding-right: 20px;
        @include sp {
            padding-right: 31/750*100vw;
        }
    }

    &__groupCompany {
        padding-bottom: 104px;
        background: url(../img/top/group_company_bg.png) no-repeat;
        background-size: cover;
        background-position: top;
        @include sp {
            padding-bottom: 234/750*100vw;
            background: url(../img/top/sp/group_company_bg_sp.png) no-repeat;
            background-size: 100% cover;
            background-position: top;
        }
    }
    &__groupCompanyBlock {
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
        width: 1120px;
        margin: 0 auto;
        padding: 75px 0;
        background: #fff;
        border-radius: 20px;
        @include sp {
            width: 672/750*100vw;
            padding: 80/750*100vw 0;
            border-radius: 15/750*100vw;
        }
    }
    &__groupCompanyInner {
        width: 1008px;
        margin: 0 auto;
        @include sp {
            width: 100%;
        }
    }
    &__groupCompanyTtl {
        text-align: center;
        margin-bottom: 59px;
        @include sp {
            margin-bottom: 71/750*100vw;
        }
        .ttl {
            font-size: 28px;
            font-weight: 500;
            letter-spacing: 0.1em;
            margin-bottom: 8px;
            @include sp {
                @include vw(46);
                margin-bottom: 2/750*100vw;
            }
        }
        .sub_ttl {
            display: block;
            font-family: 'Lato', sans-serif;
            font-size: 14px;
            letter-spacing: 0.04em;
            color: #098E75;
            @include sp {
                @include vw(28);
                text-align: center;
                margin: 0 auto;
            }
        }
    }

    &__groupCompanyBox {
        .box01 {
            display: flex;
            justify-content: space-around;
            margin-bottom: 34px;
            align-items: center;
            vertical-align:middle;
            @include sp {
                display: block;
                margin: 0;
                text-align: center;
                width: 100%;
            }
            .company01 {
                @include sp {
                    width: 329/750*100vw;
                    margin: 0 auto 40/750*100vw;
                }
            }
            .company02 {
                @include sp {
                    width: 378/750*100vw;
                    margin: 0 auto 40/750*100vw;
                }
            }
            .company03 {
                @include sp {
                    width: 449/750*100vw;
                    margin: 0 auto 40/750*100vw;
                }
            }
            a {
                display: block;
            }
        }
        .box02 {
            width: 375px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            @include sp {
                display: block;
                margin: 0;
                text-align: center;
                width: 100%;
            }
            .company04 {
                @include sp {
                    width: 254/750*100vw;
                    margin: 0 auto 40/750*100vw;
                }
            }
            .company05 {
                @include pc {
                    margin-top: -5px;
                }
                @include sp {
                    width: 260/750*100vw;
                    margin: 0 auto 40/750*100vw;
                }
            }
            a {
                display: block;
            }
        }
    }

    &__beverageBanner {
      margin-bottom: 120px;
      text-align: center;
      @include sp {
        margin-bottom: 160/750*100vw;
      }
    }
    &__beverageBanner1 {
      display: block;
      margin: 0 auto;
      @include sp {
        width: 670/750*100vw;
      }
      picture {
        margin: 0 auto;
      }
    }
}

.prod_img {
    height: 353px;
    width: 560px;
    position: relative;
    overflow: hidden;
    @include sp {
        width: 672/750*100vw;
        height: 384/750*100vw;
    }
    &--01 {
        div {
            position: absolute;
            overflow: hidden;
            width: 550px;
            height: 240px;
            border-radius: 30px;
            bottom: 0;
            transition:0.4s;
            @include sp {
                width: 672/750*100vw;
                height: 293/750*100vw;
            }
            &:after {
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                width: 550px;
                height: 240px;
                background: url(../img/top/prod_bg01.png) no-repeat;
                border-radius: 30px !important;
                background-size: 550px 240px;
                background-position: bottom;
                z-index: 10;
                transition:0.4s;
                @include sp {
                    width: 672/750*100vw;
                    height: 293/750*100vw;
                    background: url(../img/top/prod_bg01.png) no-repeat;
                    background-size:  672/750*100vw  293/750*100vw;
                }
            }
        }
        img {
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            transition:0.4s;
            z-index: 20;
            @include sp {
                width: 556/750*100vw;
                bottom: -10/750*100vw;
            }
        }
    }
    &--02 {
        @include sp {
            height: 440/750*100vw;
        }
        div {
            position: absolute;
            overflow: hidden;
            width: 550px;
            height: 240px;
            border-radius: 30px;
            bottom: 0;
            transition:0.4s;
            @include sp {
                width: 672/750*100vw;
                height: 400/750*100vw !important;
            }
            &:after {
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                width: 550px;
                height: 240px;
                background: url(../img/top/prod_bg02.png) no-repeat;
                border-radius: 30px !important;
                background-size: 550px 240px;
                background-position: bottom;
                z-index: 10;
                transition:0.4s;
                @include sp {
                    width: 672/750*100vw;
                    height: 293/750*100vw;
                    background: url(../img/top/prod_bg02.png) no-repeat;
                    background-size:  672/750*100vw  293/750*100vw;
                }
            }
        }
        img {
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            transition:0.4s;
            z-index: 20;
            @include sp {
                width: 556/750*100vw;
                bottom: -10/750*100vw;
            }
        }
    }
}

.btn_on {
    div {
        &:after {
            transform: scale(1.2);
        }
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background: url(../img/top/prod_bg_shadow.png) no-repeat;
            z-index: 120;
            width: 550px;
            height: 240px;
        }
    }
    img {
        bottom: 0 !important;
    }
}

.btnWrap01 {
    &:hover {
        .btn {
            &:after {
                right: 49px;
                @include sp {
                    right: 47/750*100vw;
                }
            }
        }
    }
}
.btnWrap02 {
    &:hover {
        .btn {
            &:after {
                right: 186px;
                @include sp {
                    right: 47/750*100vw;
                }
            }
        }
    }
}

.sub_ttl--01 {
    margin-bottom: 30px;
    font-family: 'Lato'!important;
    font-size: 14px;
    letter-spacing: 0.04em;
    color: #098E75;
    display: block;
    font-weight: 600 !important;
    @include sp {
        @include vw(28);
        text-align: center;
        margin: 0 auto 57/750*100vw;
    }
}
.sub_ttl--02 {
    margin-bottom: 30px;
    font-family: 'Lato'!important;
    font-size: 14px;
    letter-spacing: 0.04em;
    color: #098E75;
    display: block;
    font-weight: 600 !important;
    @include sp {
        @include vw(28);
        text-align: center;
        margin: 0 auto 57/750*100vw;
    }
}

.notice-covid19 {
  max-width: 640px;
  margin: 10px auto 0;
  padding: 0 20px;
}

@media screen and (max-width: 750px) {
  .notice-covid19 {
    margin-top: 30px;
  }
}

.notice-covid19__inner {
  text-align: center;
}

.notice-covid19__link {
  display: block;
  padding: 14px 0 16px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.notice-covid19__link:hover {
  text-decoration: none;
}

.notice-covid19__icon {
  position: relative;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.notice-covid19__icon::after {
  position: absolute;
  top: 50%;
  right: -16px;
  transform: translateY(-50%);
  width: 7px;
  height: 8px;
  background: url(/assets/img/top/icon-external.png) no-repeat center / contain;
  content: "";
}

.notice-covid19__spBreak {
  display: none;
}

@media screen and (max-width: 750px) {
  .notice-covid19__spBreak {
    display: inline;
  }
}