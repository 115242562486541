@charset "utf-8";

.m-slide {
    &__item {
        @include pc {
            width: 929px !important;
            height: 620px;
            position: relative;
        }
        @include sp {
            width: 672/750*100vw;
            height:1300/750*100vw;
            position: static;
        }
        .img {
            width: 558px;
            @include pc {
                margin-left: auto;
            }
            @include sp {
                width: 672/750*100vw;
                margin: 0 auto;
            }
        }
        .txt {
            position: absolute;
            // left: 2px;
            left: 7px;
            // bottom: 5px;
            top: 120px;
            width: 486px;
            padding: 48px 0 76px;
            min-height: 342px;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: #fff;
            @include pc {
                box-shadow: 0 0 5px rgba(0, 0, 0, .2);
            }
            @include sp{
                margin: 0 auto;
                display: block;
                position: static;
                padding-top: 71/750*100vw;
                // height: 529/750*100vw;
                min-height: 588/750*100vw;
                padding-bottom: 113/750*100vw;
                width: 672/750*100vw;
                border-radius: 0 0 20/750*100vw 20/750*100vw;
                box-shadow: 0px 10px 10px -5px rgba(0,0,0,0.2);

            }
            .inner {
                width: 388px;
                margin: 0 auto;
                @include sp {
                    width: 528/750*100vw;
                }
                .subTtl {
                    font-size: 14px;
                    color: #098E75;
                    letter-spacing: 0.1em;
                    padding-bottom: 11px;
                    margin-bottom: 13px;
                    position: relative;
                    @include sp {
                        @include vw(24);
                        padding-bottom: 16/750*100vw;
                        margin-bottom: 31/750*100vw;
                        display: flex;
                        justify-content: space-between;
                    }
                    &::after {
                        position: absolute;
                        content: '';
                        width: 32px;
                        height: 2px;
                        background: #098E75;
                        bottom: 0;
                        left: 0;
                        @include sp {
                            width: 51/750*100vw;
                            height: 4/750*100vw;
                        }
                    }
                    .spCount {
                        @include pc {
                            display: none;
                        }
                        span {
                            display: inline-block;
                            margin: 0 8.5/750*100vw;
                        }
                    }
                }
                h4 {
                    font-size: 26px;
                    font-weight: 500;
                    letter-spacing: 0.05em;
                    line-height: 1.38;
                    margin-bottom: 24px;
                    // position: relative;
                    @include sp {
                        @include vw(32);
                        margin-bottom: 40/750*100vw;
                    }
                    // &::after {
                    //     position: absolute;
                    //     content: '';
                    //     width: 36px;
                    //     height: 2px;
                    //     background: #098E75;
                    //     border-radius: 2px;
                    //     bottom: 0;
                    //     left: 0;
                    //     @include sp {
                    //         width: 51/750*100vw;
                    //         height: 4/750*100vw;
                    //     }
                    // }
                }
                p {
                    font-size: 16px;
                    letter-spacing: 0.1em;
                    line-height: 2.25;
                    @include sp{
                        @include vw(26);
                        line-height: 1.9;
                    }
                    span {
                        font-size: 12px;
                        display: block;
                    }
                }
            }
        }
    }

    &__prev01 {
        top: 450px !important;
        left: 809px !important;
        width: 10px;
        height: 20px;
        background: url(../img/common/prev.svg) no-repeat;
        background-size: cover;
        @include sp {
            top: 50% !important;
            transform: translateY(-50%);
            left: 0 !important;
            background: url(../img/common/prev_sp.svg) no-repeat;
            background-size: 60/750*100vw 60/750*100vw;
            width: 60/750*100vw;
            height: 60/750*100vw;
        }
    }
    &__next01 {
        top: 450px !important;
        right: 0 !important;
        width: 10px;
        height: 20px;
        background: url(../img/common/next.svg) no-repeat;
        background-size: cover;
        @include sp {
            top: 50% !important;
            transform: translateY(-50%);
            right: -0/750*100vw !important;
            background: url(../img/common/next_sp.svg) no-repeat;
            background-size: 60/750*100vw 60/750*100vw;
            width: 60/750*100vw;
            height: 60/750*100vw;
            z-index: 20;
        }
    }
    &__pagenation01 {
        bottom: 177px !important;
        left: 845px !important;
        width: 68px;
        display: flex;
        @include sp {
            width: 95/750*100vw;
            bottom: 47/750*100vw !important;
            left: 295/750*100vw !important;
        }
    }
    &__count {
        position: absolute;
        top: 427px !important;
        left: 843px !important;
        font-family: 'Lato', sans-serif !important;
        font-size: 14px;
        letter-spacing: 0.1em;
        color: #098E75;
        display: flex;
        width: 60px;
        justify-content: space-between;
        .partition {
            display: inline-block;
            // margin: 0 8.5px;
        }
        @include sp {
            display: none;
        }
        span {
            display: block;
        }
    }
}

.swiper-pagination-bullet {
    // width: 12px;
    // height: 12px;
    border-radius: 50%;
    border: 1px solid #098E75;
    opacity: 1;
    background: #fff;
}
.swiper-pagination-bullet-active {
    // width: 12px;
    // height: 12px;
    border-radius: 50%;
    border: 1px solid #098E75;
    opacity: 1;
    background: #098E75;
}
.swiper-container {
    position: relative;
}

