@charset "utf-8";

.p-about {
    padding-bottom: 200px;
    padding-top: 72px;
    @include sp {
        padding-top: 87/750*100vw;
        padding-bottom: 266/750*100vw;
    }
    &__kv {
        width: 100%;
        height: 443/1366*100vw;
        padding-top: 147/1366*100vw;
        background: url(../img/about/about_top_bg.jpg) no-repeat;
        background-size: cover;
        position: relative;
        @include sp {
            height: 558/750*100vw;
            padding-top: 163/750*100vw;
            background: url(../img/about/sp/about_top_bg_sp.jpg) no-repeat;
            background-size: cover;
        }
        .mask {
            position: absolute;
            bottom: 0;
            width: 100%;
            @include sp {
                display: none;
            }
            img {
                width: 100%;
            }
        }
    }
    &__ttl {
        width: 1120px;
        margin: 0 auto;
        @include sp {
            width: 672/750*100vw;
        }
        .ttl {
            display: block;
            font-size: 42px;
            letter-spacing: 0.1em;
            line-height: 1.2;
            color: #fff;
            font-weight: 500;
            margin-bottom: 5px;
            @include sp {
                @include vw(50);
                margin-bottom: 6/750*100vw;
            }
        }
        .sub_ttl {
            font-family: 'Lato', sans-serif !important;
            font-size: 20px;
            letter-spacing: 0.04em;
            color: #fff;
            @include sp {
                @include vw(28);
            }
        }
    }

    &__inner {
        width: 1120px;
        margin: 0 auto;
        @include sp {
            width: 672/750*100vw;
        }
    }
    &__contWrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include sp {
            display: block;
        }
    }
    &__contBlock {
        display: block;
        width: 530px;
        position: relative;
        margin-bottom: 126px;
        @include sp {
            width: 100%;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &:before {
            position: absolute;
            content: '';
            width: 28px;
            height: 28px;
            border: 1px solid #098E75;
            border-radius: 50%;
            left: 417px;
            bottom: 32px;
            z-index: 150;
            @include sp {
                width: 46/750*100vw;
                height: 46/750*100vw;
                left: 510/750*100vw;
                bottom: 62/750*100vw;
            }
        }
        &:after {
            position: absolute;
            content: '';
            width: 17.5px;
            height: 3.5px;
            background: url(../img/common/arrow.svg) no-repeat;
            background-size: cover;
            bottom: 46px;
            left: 423px;
            transition: 0.4s;
            @include sp {
                width: 29/750*100vw;
                height: 6/750*100vw;
                bottom: 83/750*100vw;
                left: 522/750*100vw;
            }
        }
        &:hover::after {
            left: 433px;
            @include sp {
                left: 533/750*100vw;
            }
        }
    }
    &__contTxt {
        position: absolute;
        left: 0;
        background: #fff;
        width: 490px;
        height: 156px;
        padding-top: 40px;
        padding-left: 40px;
        border-radius: 0 10px 0 0;
        @include pc {
            bottom: -46px;
        }
        @include sp {
            width: 612/750*100vw;
            height: auto;
            padding: 40/750*100vw 56/750*100vw;
            border-radius: 0 30/750*100vw 0 0;
            top: 277/750*100vw;
            left: -5/750*100vw;
        }
        .ttl {
            display: block;
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 13px;
            letter-spacing: 0.1em;
            @include sp {
                @include vw(36);
                margin-bottom: 19/750*100vw;
            }
        }
        .txt {
            display: block;
            font-size: 16px;
            letter-spacing: 0.1em;
            line-height: 1.3;
            width: 382px;
            @include sp {
                @include vw(26);
                width: 454/750*100vw;
            }
        }
    }
}