@charset "utf-8";

.p-philosophy {
    padding-top: 72px;
    padding-bottom: 132px;
    @include sp {
        padding-top: 87/750*100vw;
        padding-bottom: 266/750*100vw;
    }
    &__kv {
        width: 100%;
        height: 443/1366*100vw;
        padding-top: 147/1366*100vw;
        background: url(../img/philosophy/philosophy_bg.jpg) no-repeat;
        background-size: cover;
        position: relative;
        @include sp {
            height: 558/750*100vw;
            padding-top: 163/750*100vw;
            background: url(../img/philosophy/sp/philosophy_bg_sp.jpg) no-repeat;
            background-size: cover;
        }
    }

    &__inner {
        width: 930px;
        margin: 0 auto;
        @include sp {
            width: 672/750*100vw;
        }
    }
    &__list {
        width: 100%;
        margin-bottom: 112px;
        @include sp {
            margin-bottom: 181/750*100vw;
        }
        .item {
            margin-bottom: 29px;
            padding-left: 24px;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.1em;
            line-height: 1.37;
            position: relative;
            @include sp {
                @include vw(26);
                width: 100%;
                padding-left: 38/750*100vw;
                margin-bottom: 39/750*100vw;
                font-weight: 500;
            }
            &:before {
                position: absolute;
                left: 0;
                top: -3px;
                font-size: 18px;
                color: #098E75;
                @include sp {
                    @include vw(28);
                    top: -0.8vw;
                }
            }
            &--01 {
                &:before {
                    content: '1.';
                }
            }
            &--02 {
                &:before {
                    content: '2.';
                }
            }
            &--03 {
                &:before {
                    content: '3.';
                }
            }
            &--04 {
                &:before {
                    content: '4.';
                }
            }
            &--05 {
                &:before {
                    content: '5.';
                }
            }
            &--06 {
                &:before {
                    content: '6.';
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__policy {
        .block_ttl {
            margin-bottom: 42px;
            @include sp {
                margin-bottom: 72/750*100vw;
            }
        }
    }
    &__policyTtl {
        font-size: 22px;
        letter-spacing: 0.08em;
        padding-bottom: 9px;
        margin-bottom: 30px;
        position: relative;
        @include sp {
            @include vw(32);
            padding-bottom: 7/750*100vw;
            margin-bottom: 40/750*100vw;
        }
        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 2px;
            background: #E8EAEA;
            bottom: 0;
            left: 0;
            @include sp {
                height: 4/750*100vw;
            }
        }
        &::after {
            position: absolute;
            content: '';
            width: 36px;
            height: 2px;
            background: #098E75;
            bottom: 0;
            left: 0;
            @include sp {
                width: 51/750*100vw;
                height: 4/750*100vw;
            }
        }
    }
    &__policyTxt {
        font-size: 16px;
        letter-spacing: 0.1em;
        margin-bottom: 77px;
        font-weight: 500;
        @include sp {
            @include vw(26);
            margin-bottom: 143/750*100vw;
            font-weight: 500;
        }
    }
    &__policyList {
        .item {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.1em;
            margin-bottom: 29px;
            padding-left: 22px;
            position: relative;
            @include sp {
                @include vw(26);
                width: 100%;
                line-height: 1.9;
                padding-left: 38/750*100vw;
                margin-bottom: 39/750*100vw;
            }
            &:last-child {
                margin-bottom: 0;
            }
            &:before {
                position: absolute;
                font-size: 18px;
                left: 0;
                top: -3px;
                color: #098E75;
                @include sp {
                    @include vw(26);
                    top: -0.2vw;
                }
            }
            &--01 {
                &:before {
                    content: '1.';
                }
            }
            &--02 {
                &:before {
                    content: '2.';
                }
            }
            &--03 {
                &:before {
                    content: '3.';
                }
            }
            &--04 {
                &:before {
                    content: '4.';
                }
            }
            &--05 {
                &:before {
                    content: '5.';
                }
            }
            &--06 {
                &:before {
                    content: '6.';
                }
            }
        }
    }
}