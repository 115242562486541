@charset "utf-8";
.l-header{

}

.l-headerPc {
    width: 100%;
    padding: 17px 60px;
    background: #fff;
    position: fixed;
    z-index: 300;
    @include sp {
        display: none;
    }
    &__inner {
        width: 1280/1366*100vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__logoSmall {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__logo {
        @include pcMin {
            display: block;
            width: 220px;
        }
    }
}
.l-headerSp {
    padding: 20/750*100vw 39/750*100vw 20/750*100vw 30/750*100vw;
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 300;
    @include pc {
        display: none;
    }
    &__inner {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.l-headerTopPc {
    // border: 1px solid #000;
    border-radius: 12px;
    position: fixed;
    top: 20px !important;
    left: 50%;
    transform: translateX(-50%);
    width: 1327/1366*100vw;
    margin: 0 auto;
    padding: 17px 40px;
    background: #fff;
    z-index: 300;
    @include sp {
        display: none;
    }
    &__inner {
        width: 100%;
        display: flex;
        justify-content: space-between;
        vertical-align: center;
        align-items: center;
    }
    &__menuList {
        width: 712px;
        display: flex;
        justify-content: space-between;
        @include pcMin {
            width: 652px;
        }
        .active {
            color: #098E75;
            position: relative;
            font-size: 15px;
            font-weight: 500;
            &::after {
                position: absolute;
                content: '';
                width: 100%;
                left: 0;
                height: 3px;
                bottom: -25.5px;
                background: #098E75;
            }
        }
    }
    &__menu {
        font-size: 15px;
        font-weight: 500;
    }
    &__logoSmall {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__logo {
        @include pcMin {
            display: block;
            width: 220px;
        }
        img {
            @include pcMin {
                width: 100%;
            }
        }
    }
}

.l-headerTopSp {
    @include pc {
        display: none;
    }
    width: 674/750*100vw;
    position: fixed;
    top: 30/750*100vw !important;
    z-index: 300;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border-radius: 2vw;
    padding: 30/750*100vw 24/750*100vw;
    &__inner {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__logo {
        width: 330/750*100vw;
    }
    &__hbgIcon {
        width: 32/750*100vw;
        height: 22/750*100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span {
            display: block;
            width: 32/750*100vw;
            height: 2/750*100vw;
            background: #098E75;
        }
    }
    &__hbgIcon.isactive {
        position: relative;
        span {
            &:first-child {
                position: absolute;
                transform: rotate(45deg);
                bottom: 55%;
            }
            &:nth-child(2) {
                display: none;
            }
            &:last-child {
                position: absolute;
                transform: rotate(-45deg);
                top: 40%;
            }
        }
    }
}

.l-headerTopSp.isactive {
    top: 0 !important;
    height: 143/750*100vw !important;
    padding: 40/750*100vw 38/750*100vw !important;
    width: 100%;
    border-radius: 0;
}

.headerWide {
    width: 100%;
    top: 0 !important;
    border-radius: 0;
}