@charset "utf-8";

.m-breadcrumbs {
    margin-top: 24px;
    padding: 0 124px;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 200;
    &--other {
        padding: 0;
        width: 1120px;
        margin: 0 auto 72px;
        @include sp {
            width: 672/750*100vw;
            margin: 0 auto 80/750*100vw;
        }
    }
    &--long {
        padding: 0;
        width: 1120px;
        margin: 0 auto 72px;
        @include sp {
            flex-wrap: wrap;
            width: 420/750*100vw;
            margin: 0 0 80/750*100vw 300/750*100vw;
        }
    }
    @include sp {
        margin-top: 40/750*100vw;
        padding: 0 39/750*100vw;
    }
    a {
        display: block;
        margin-right: 8px;
        font-size: 12px;
        letter-spacing: 0.1em;
        @include sp {
            @include vw(18);
            margin-right: 16/750*100vw;
        }
    }
    span {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 1px;
        margin-right: 10px;
        @include sp {
            padding-bottom: 0;
            margin-right: 27/750*100vw;
        }
    }
    p {
        font-size: 12px;
        letter-spacing: 0.1em;
        @include sp {
            @include vw(18);
        }
    }
}