@charset "utf-8";

.m-ham {
    position: fixed;
    display: none;
    top: 142/750*100vw;
    z-index: 300;
    left: 0;
    width: 100vw;
    background: #fff;
    &--01 {
        top: 87/750*100vw;;
    }
    &__menuList {
        width: 674/750*100vw;
        margin: 0 auto;
    }
    &__menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 106/750*100vw;
        border-top: 1px solid #E8EAEA;
    }
}