@charset "utf-8";

.m-btn{
    &__arrow {
        width: 127px;
        height: 29px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid #098E75;
        border-radius: 14px;
        color: #098E75;
        padding-left: 21px;
        font-size: 12px;
        letter-spacing: 0.1em;
        position: relative;
        &--dev {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 361px !important;
            height: 60px !important;
            border-radius: 30px;
            margin: 0 auto;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            padding-left: 0 !important;
            padding-right: 10px;
            @include sp {
                width: 500/750*100vw !important;
                height: 100/750*100vw !important;
                border-radius: 100/750*100vw !important;
                @include vw(28);
                padding-left: 0 !important;
                padding-right: 2.5vw;
            }
            &::after {
                top: 50% !important;
                transform: translateY(-50%);
                
            }
        }
        &--cont {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 361px !important;
            height: 60px !important;
            border-radius: 30px;
            margin: 0 auto !important;
            font-size: 18px;
            font-weight: 600;
            position: relative;
            background: #fff;
            cursor: pointer;
            padding-left: 114px;
            @include sp {
                width: 500/750*100vw !important;
                height: 100/750*100vw !important;
                border-radius: 50/750*100vw !important;
                padding-left: 146/750*100vw !important;
                @include vw(28);
            }
            &::after {
                width: 22px !important;
                height: 4px !important;
                top: 28px !important;
                right: 20px !important;
                @include sp {
                    width: 44/750*100vw !important;
                    height: 8/750*100vw !important;
                    top: 46/750*100vw !important;
                    right: 32/750*100vw !important;
                }
            }
            &:hover::after {
                right: 10px !important;
                @include sp {
                    right: 20/750*100vw !important
                }
            }
        }
        @include sp {
            @include vw(24);
            padding-left: 49/750*100vw;
            width: 259/750*100vw;
            height: 56/750*100vw;
        }
        &::after {
            position: absolute;
            content: '';
            width: 20px;
            height: 4px;
            background: url(../img/common/arrow.svg) no-repeat;
            background-size: cover;
            top: 13px;
            right: 11px;
            transition:0.4s;
            @include sp {
                width: 36/750*100vw;
                height: 7/750*100vw;
                top: 21/750*100vw;
                right: 19/750*100vw;
            }
        }
        &:hover::after {
            right: 4px;
            @include sp {
                right: 12/750*100vw;
            }
        }
        &--cont02 {
            padding-left: 162px !important;
            @include sp {
                padding-left: 221/750*100vw !important;
            }
        }
    }
    &__arrowWt {
        width: 239px;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 71px;
        color: #fff;
        background: #098E75;
        border-radius: 30px;
        font-size: 18px;
        letter-spacing: 0.1em;
        position: relative;
        @include sp {
            width: 500/750*100vw;
            height: 100/750*100vw;
            @include vw(28);
            padding-left: 0;
            text-align: center;
        }
        &::after {
            position: absolute;
            content: '';
            width: 22px;
            height: 4px;
            background: url(../img/common/arrow_wt.svg) no-repeat;
            background-size: cover;
            top: 26px;
            right: 20px;
            transition:0.4s;
            @include sp {
                width: 48/750*100vw;
                height: 8/750*100vw;
                top: 45 /750*100vw;
                right: 39/750*100vw;
            }
        }
        &:hover::after {
            right: 10px;
            @include sp {
                right: 16/750*100vw;
            }
        }
    }
}
