@charset "utf-8";
.m-wave {
    position: absolute;
    bottom: -40/1366*100vw;
    z-index: 150;
    height: 220/1366*100vw;
    @include sp {
        bottom: -15/375*100vw;
        height: 310/750*100vw;
    }
    .wave {
      height: 220/1366*100vw;
      position: relative;
      z-index: 9999;
      @include sp {
        height: 310/750*100vw;
      }
      #waveCanvas {
        z-index: 9999;
        position: absolute;
        bottom: 0;
        height: 220/1366*100vw;
        @include sp {
          width: 100vw;
          height: 310/750*100vw;
        }
      }
    }
}