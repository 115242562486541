@charset "utf-8";

.p-contact {
    // padding: 47px 0 132px;
    padding: 96px 0 132px;
    @include sp {
        // padding: 70/750*100vw 0 240/750*100vw;
        padding: 70/750*100vw 0 240/750*100vw;
    }
    .m-breadcrumbs {
        @include pc {
            padding: 0 !important;
            width: 1120px !important;
            margin: 0 auto 72px !important;
        }
        @include sp {
            margin-top: 7.5vw;
            margin-right: 3.5vw;
        }
    }
    &__ttl {
        width: 1120px;
        margin: 47px auto 80px;
        @include sp {
            width: 672/750*100vw;
            margin: 70/750*100vw auto 128/750*100vw;
        }
        .ttl {
            display: block;
            margin-bottom: 5px;
            font-size: 42px;
            font-weight: 500;
            letter-spacing: 0.1em;
            @include sp {
                @include vw(50);
                margin-bottom: 0;
            }
        }
        .sub_ttl {
            font-family: 'Lato', sans-serif !important;
            font-size: 20px;
            letter-spacing: 0.04em;
            color: #098E75;
            @include sp {
                @include vw(28);
            }
        }
    }

    &__location {
        width: 100%;
        text-align: center;
        padding: 29px 0;
        background: #F6FAF3;
        margin-bottom: 60px;
        @include pc {
            padding-right: 20px;
        }
        @include sp {
            padding: 80/750*100vw 0 77/750*100vw;
            padding-right: 15/750*100vw;
        }
        img {
            @include sp {
                width: 627/750*100vw;
            }
        }
    }

    &__inner {
        width: 938px;
        margin: 0 auto;
        @include sp {
            width: 672/750*100vw;
        }
    }

    &__txt {
        font-size: 16px;
        line-height: 1.8;
        letter-spacing: 0.1em;
        margin-bottom: 78px;
        @include sp {
            @include vw(26);
            line-height: 1.9;
            margin-bottom: 151/750*100vw;
        }
    }
    &__desc {
        font-size: 16px;
        line-height: 1.8;
        letter-spacing: 0.1em;
        margin-bottom: 40px;
        @include sp {
            padding-left: 0;
            @include vw(26);
            line-height: 1.9;
            margin-bottom: 55/750*100vw;
        }
        span {
            color: #E60012;
        }
    }

    form {
        @include sp {
        }
        .item {
            padding: 40px 0;
            border-top: 1px solid #E8EAEA;
            display: flex;
            align-items: center;
            @include sp {
                padding: 55/750*100vw 0 60/750*100vw;
                flex-wrap: wrap;
                width: 672/750*100vw;
            }
            &:last-child {
                border-bottom: 1px solid #E8EAEA;
            }
            label {
                width: 265px;
                font-size: 14px;
                letter-spacing: 0.1em;
                margin-bottom: auto;
                span {
                    display: inline-block;
                    color: #E60012;
                    font-size: 12px;
                    padding-left: 15px;
                    @include sp {
                        @include vw(18);
                        padding-left: 30/750*100vw;
                    }
                }
                @include sp {
                    @include vw(26);
                    width: 100%;
                    margin-bottom: 30/750*100vw;
                }
            }
            input {
                background: #FAFAFA;
                border: none;
                height: 44px;
                border-radius: 6px;
                width: 665px;
                @include sp {
                    height: 85/750*100vw;
                    width: 672/750*100vw;
                }
            }
        }
        span {
            display: block;
            margin-top: 15px;
            padding-left: 22px;
            font-size: 12px;
            @include sp {
                @include vw(24);
                padding-left: 0;
                margin-top: 27/750*100vw;
            }
        }
        .mail_check {
            margin-bottom: 15px;
            @include sp {
                @include vw(24);
                padding-left: 0;
                margin-top: 27/750*100vw;
            }
        }
        #postal_code {
            width: 94px;
            margin: 0 8px;
            @include sp {
                width: 206/750*100vw;
                height: 85/750*100vw;
            }
        }
        .error_msg {
            display: none;
        }
        p {
            font-size: 14px;
            letter-spacing: 0.1em;
            line-height: 1.4;
            @include sp {
                width: 100%;
                margin-bottom: 19/750*100vw;
                line-height: 1.2;
            }
            &:first-child {
                width: 265px;
                @include sp {
                    width: 100%;
                }
            }
            span {
                display: inline-block;
                color: #E60012;
                margin-top: 0;
                padding-left: 18px;
                @include sp {
                    @include vw(18);
                    padding-left: 15/750*100vw;
                }
            }
        }
    }
    select {
        width: 250px;
        height: 44px;
        font-size: 12px;
        background: #FAFAFA;
        border: none;
        border-radius: 6px;
        @include sp {
            @include vw(24);
            width: 672/750*100vw;
            height: 85/750*100vw;
        }
    }
    textarea {
        width: 665px;
        height: 251px;
        background: #FAFAFA;
        border: none;
        border-radius: 6px;
        @include sp {
            width: 672/750*100vw;
            height: 400/750*100vw;
        }
    }

    &__confirmationTxt {
        text-align: center;
        margin-bottom: 30px;
        font-size: 14px;
        letter-spacing: 0.1em;
        line-height: 1.7;
        @include sp {
            @include vw(26);
            text-align: left;
            margin-bottom: 71/750*100vw;
        }
        a {
            color: #098E75;
        }
    }
    &__agreement {
        text-align: center;
        margin-bottom: 50px;
        @include sp {
            margin-bottom: 100/750*100vw;
        }
        input {
            width: 20px;
            height: 20px;
            margin-right: 16px;
            @include sp {
                width: 40/750*100vw;
                height: 40/750*100vw;
                margin-right: 29/750*100vw;
            }
        }
        label {
            font-size: 14px;
            letter-spacing: 0.1em;
            vertical-align: top;
            @include sp {
                @include vw(26);
            }
        }
    }

    &__btn {
        // display: block;
        @include pc {
            width: auto !important;
            padding: 0 110px !important;
            // padding-left: 160px;
            // padding-right: 20px;
            // text-align: left;
        }
        margin: 0 auto !important;
        font-family: "Noto Sans JP","ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","ヒラギノ丸ゴ ProN W4","Hiragino Maru Gothic ProN","ヒラギノ丸ゴ Pro W4","Hiragino Maru Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック","MS PGothic",Sans-Serif;
    }
}

.error {
    input {
        background: rgba(230, 0, 18, 0.3) !important;
    }
    select {
        background: rgba(230, 0, 18, 0.3) !important;
    }
    .error_msg {
        display: block !important;
        color: #E60012;
    }
}

.postal_code {
    display: flex;
    align-items: center;
}

.item_last {
    margin-bottom: 100px;
    border-bottom: 1px solid #E8EAEA;
    @include sp {
        margin-bottom: 80/750*100vw;
    }
}

.p-contactThanks {
    // margin-bottom: 98px;
    &__ttl {
        font-size: 32px;
        font-weight: bold;
        color: #098E75;
        letter-spacing: 0.1em;
        text-align: center;
        margin-bottom: 46px;
        @include sp {
            @include vw(46);
            margin-bottom: 58/750*100vw;
        }
    }
    &__txt {
        font-size: 16px;
        line-height: 1.7;
        letter-spacing: 0.1em;
        text-align: center;
        margin-bottom: 98px;
        @include sp {
            @include vw(26);
            margin-bottom: 96/750*100vw;
        }
    }

    &__btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: 18px;
        font-family: 600;
        color: #098E75;
        width: 360px;
        height: 60px;
        border: 1px solid #098E75;
        border-radius: 30px;
        margin: 0 auto;
        position: relative;
        @include sp {
            @include vw(28);
            width: 500/750*100vw;
            height: 100/750*100vw;
            border-radius: 50/750*100vw;
        }
        &::before {
            position: absolute;
            content: '';
            width: 22px;
            height: 4px;
            background: url(../img/common/arrow_back.svg) no-repeat;
            top: 28px;
            left: 20px;
            transition: 0.4s;
            @include sp {
                width: 44/750*100vw;
                height: 8/750*100vw;
                top: 46/750*100vw;
                left: 32/750*100vw;
            }
        }
        &:hover::before {
            left: 10px;
            @include sp {
                left: 12/750*100vw;
            }
        }
    }
}
