@charset "utf-8";
.l-footer{
    background: #098E75;
    padding-top: 60px;
    @include sp {
        padding: 60/750*100vw 0;
    }
    &__inner {
        width: 1120px;
        margin: 0 auto;
        @include sp {
            width: 100%;
        }
    }
    &__top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 60px;
        border-bottom: 2px solid #fff;
        @include sp {
            display: block;
            padding-bottom: 0;
            border-bottom: 1px solid #fff;
        }
    }
    &__logo {
        @include sp {
            display: block;
            width: 100%;
            text-align: center;
            margin-bottom: 60/750*100vw;
        }
        img {
            @include sp {
                display: block;
                width: 520/750*100vw;
                margin: 0 auto;
            }
        }
    }
    &__menu {
        width: 645px;
        display: flex;
        justify-content: space-between;
        @include sp {
            display: none;
        }
        div {
            a {
                display: block;
                font-size: 14px;
                color: #fff;
                margin-bottom: 17px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .menu03 {
            a {
                margin-bottom: 5px;
            }
            ul {
                li {
                    position: relative;
                    padding-left: 1em;
                    &:before {
                        position: absolute;
                        content: '';
                        width: 0.5em;
                        height: 1px;
                        background: #fff;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
    &__menuSp {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid #fff;
        @include pc {
            display: none;
        }
        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            width: 50%;
            height: 100/750*100vw;
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
            @include vw(26);
            color: #fff;
            &:nth-child(even) {
                border-right: none;
            }
            &:last-child {
                width: 100%;
                border-bottom: none !important;
                border-right: none;
            }
        }
    }
    &__bottom {
        padding: 17px 0 16px;
        display: flex;
        justify-content: space-between;
        @include sp {
            padding: 37/750*100vw 0 20/750*100vw;
            display: block;
        }
    }
    &__bottomLink {
        width: 291px;
        display: flex;
        justify-content: space-between;
        @include pcMin {
            width: 320px;
        }
        @include sp {
            width: 542/750*100vw;
            margin: 0 auto 37/750*100vw;
        }
        a {
            font-size: 12px;
            color: #fff;
            letter-spacing: 0.1em;
            @include sp {
                @include vw(18)
            }
        }
    }
    &__copy {
        font-size: 12px;
        color: #fff;
        letter-spacing: 0.1em;
        @include sp {
            display: block;
            @include vw(16);
            text-align: center;
        }
    }
}
