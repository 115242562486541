@charset "utf-8";

.p-productsItem {
    padding-bottom: 132px;
    padding-top: 72px;
    @include sp {
        padding-top: 87/750*100vw;
        padding-bottom: 266/750*100vw
    }
    &__kv {
        width: 100%;
        height: 443/1366*100vw;
        background-size: cover;
        position: relative;
        padding-top: 147/1366*100vw;
        @include sp {
            height: 558/750*100vw;
            padding-top: 163/750*100vw;
        }
        &--01 {
            background: url(../img/products/prod_item01_bg.jpg) no-repeat;
            background-size: cover;
            @include sp {
                background: url(../img/products/sp/prod_item01_bg_sp.jpg) no-repeat;
            background-size: cover;
            }
        }
        &--02 {
            background: url(../img/products/prod_item02_bg.jpg) no-repeat;
            background-size: cover;
            @include sp {
                background: url(../img/products/sp/prod_item02_bg_sp.jpg) no-repeat;
            background-size: cover;
            }
        }
    }

    &__inner {
        width: 1120px;
        margin: 0 auto;
        @include sp {
            width: 672/750*100vw;
        }
    }
    &__cont {
        display: flex;
        justify-content: space-between;
        margin-bottom: 72px;
        @include sp {
            display: block;
            margin-bottom: 100/750*100vw;
        }
        .img{
            @include sp {
                width: 100%;
                margin-bottom: 66/750*100vw;
            }
        }
        .txt {
            width: 548px;
            @include sp {
                width: 100%;
            }
            h3 {
                font-size: 32px;
                font-weight: 500;
                letter-spacing: 0.1em;
                line-height: 1.6;
                margin-bottom: 30px;
                @include sp {
                    @include vw(46);
                    margin-bottom: 57/750*100vw;
                    text-align: center;
                    line-height: 1.2;
                }
            }
            p {
                font-size: 16px;
                letter-spacing: 0.1em;
                line-height: 2.1;
                margin-bottom: 40px;
                @include sp {
                    @include vw(26);
                    line-height: 1.8;
                    margin-bottom: 76/750*100vw;
                }
            }
            .btn {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                width: 361px;
                height: 60px;
                border-radius: 30px;
                font-size: 18px;
                font-family: 500;
                color: #098E76;
                border: 1px solid #098E76;
                position: relative;
                @include sp {
                    width:500/750*100vw ;
                    height: 100/750*100vw;
                    border-radius: 50/750*100vw;
                    @include vw(28);
                    margin: 0 auto;
                }
                &::after {
                    position: absolute;
                    content: '';
                    width: 18px;
                    height: 14.2px;
                    background:  url(../img/common/blank.svg) no-repeat;
                    background-size: cover;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    @include sp {
                        width: 30/750*100vw;
                        height: 24/750*100vw;
                        right: 31/750*100vw;
                    }
                }
            }
        }
    }
    &__date {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 100px;
        &--ryokucha {
            @include pc {
                width: 66%;
                margin: 0 auto 100px;
            }
        }
        @include sp {
            display: block;
            margin-bottom: 120/750*100vw;
        }
        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            width: 360px;
            height: 96px;
            background: #F8FAFA;
            border-radius: 20px;
            @include sp {
                width: 100%;
                height: 116/750*100vw;
                text-align: left;
                padding-left: 56/750*100vw;
                margin-bottom: 24/750*100vw;
            }
            &:last-child {
                @include sp {
                    margin-bottom: 0;
                }
            }
            p {
                font-size: 16px;
                letter-spacing: 0.1em;
                @include sp {
                    @include vw(26);
                }
                span {
                    &:first-child {
                        color: #098E75;
                    }
                    &:last-child {
                        display: inline-block;
                        width: 32px;
                        height: 1px;
                        margin: 0 8px;
                        background: #000;
                        vertical-align: middle;
                        @include sp {
                            width: 40/750*100vw;
                            height: 2/750*100vw;
                            margin: 0 16/750*100vw;
                        }
                    }
                }
            }
        }
    }
    &__btn {
        background: #fff;
        color: #098E75;
        width: 361px;
        height: 60px;
        border-radius: 30px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        margin: 0 auto;
        @include pc {
            padding-right: 20px;
        }
        @include sp {
            width: 500/750*100vw;
            height: 100/750*100vw;
            border-radius: 50/750*100vw;
            @include vw(28);
            padding-right: 30/750*100vw;
        }
        &::after {
            top: 50%;
            transform: translateY(-50%);
        }
    }
}